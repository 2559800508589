import React from 'react'
import styled from 'styled-components'
import redJoker from './img/redjoker.png'
import blackJoker from './img/blackjoker.png'

const Piece = styled.div`
border: solid black 3px;
text-align: center;
font-size: 1.5rem;
border-radius: 4px;
`


const BoardPiece = ({newGroupIds, boardIndex, group, tileWidth, tilesColored, theFont, width, pieceInfo, theId, dragFunctions}) => {



    let theColor;

    if (tilesColored === false) {
        theColor = 'white'
    } else if (pieceInfo.color === "orange") {
        theColor = "#fff4eb"
    } else if (pieceInfo.color === "red") {
        theColor = "#fff0f0"
    } else if (pieceInfo.color === "blue") {
        theColor = "#ebebff"
    } else if (pieceInfo.color === "black") {
        theColor = "#f2f2f2"
    }

    // let styleObj = {
    //     40: {
    //         1:"10.4%",
    //         3:"10.617%",
    //         5:"10.59%"
    //     },
    //     45: {
    //         1:"8.8%",
    //         3:"11.31%",
    //         5:"11.99%"
    //     },
    //     50: {
    //         1:"11.6%",
    //         3:"15.05%",
    //         5:"13.85%"
    //     },
    //     55: {
    //         1:"14.39%",
    //         3:"12.75%",
    //         5:"13.98%"
    //     },
    //     60: {
    //         1:"13.25%",
    //         3:"13.5%",
    //         5:"12.9%"
    //     },
    //     65: {
    //         1:"14.9%",
    //         3:"11.8%",
    //         5:"14.645%"
    //     },
    //     70: {
    //         1:"14.2%",
    //         3:"15%",
    //         5:"12%"
    //     },
    //     75: {
    //         1:"14.3%",
    //         3:"14.3%",
    //         5:"13.45%"
    //     },
    //     80: {
    //         1:"19.5%",
    //         3:"15.85%",
    //         5:"13.12%"
    //     },
    //     85: {
    //         1:"17.6%",
    //         3:"14.47%",
    //         5:"11.5%"
    //     },
    //     90: {
    //         1:"17.75%",
    //         3:"13.95%",
    //         5:"9.4%"
    //     },
    //     95: {
    //         1:"16.9%",
    //         3:"13.4%",
    //         5:"13.45%"
    //     }
    // }


    return (
        <Piece style={{display: `${pieceInfo.value === 0 ? "flex" : "default"}`, flexDirection: "column", justifyContent: "flex-start", width: tileWidth, color: pieceInfo.color, backgroundColor: theColor, fontSize: theFont}} draggable="true" onDragStart={dragFunctions.handleDragStart} id={pieceInfo.id} className={theId === 0 ? "0" : theId}>
                    {!pieceInfo.value || pieceInfo.value === 0 || pieceInfo.value === "Wild" ? <img alt="joker" draggable="false" style={{maxWidth: "100%", borderRadius: "50%", transform: `scale(${width.groupSize === 1 ? ".68" : width.groupSize === 3 ? ".67" : ".69"})`}} src={pieceInfo.color === "red" ? redJoker : blackJoker}/> :  <p>{pieceInfo.value}</p>}
        </Piece>
    )
}

export default BoardPiece
