import React from 'react'
import styled from 'styled-components'
import Example from './helpers/spinner.js'


import BoardPlay from './BoardPlay'


const Div = styled.div`
margin: 0 auto;
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
font-size: 1rem;
text-align: center;
`
const Buttons = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
button {
    margin: auto 1%;
    border-radius: 10px;
    padding: 1%;
    border: solid black 3px;
}
`


const TheBoard = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
margin: 2% auto;
`

const Board = ({gameInfo, gameInfoStatus, boardStatus, turnId, playerId, turnName, players, playerName, loading, setLoading, endTurn, setMessage, addFirstGroupHelperTwo, draw, moves, message, stillInTurn, dark, light, darkMode, tilesColored, width, wildTime, withWildTime, newWildIndex, oldWildIndex, submittingFirstGroup, setSubmittingFirstGroup, hasDrawn, totalMoves, setTotalMoves, resetBoard, newGroupIds, setNewGroupIds, movedOnce, getGroupValue, addNewGroupToBoard, dropHandler, isValidPlay, takenFromHand, boardInfo, boardCopy, originalBoardState, myTurn, dragFunctions, setBoardCopy}) => {

    let firstTurnHasntMoved;
    let firstTurnHasMoved;
    let notFirstNoMoves;
    let notFirstMoves;
    
    if (myTurn && stillInTurn && movedOnce) {
        firstTurnHasMoved = true;
        firstTurnHasntMoved = false
        notFirstNoMoves = false
        notFirstMoves = false
    } else if (myTurn && movedOnce && moves === 0) {
        notFirstNoMoves = true
        firstTurnHasMoved = false
        firstTurnHasntMoved = false
        notFirstMoves = false
    } else if (myTurn && movedOnce && moves > 0) {
        notFirstMoves = true
        notFirstNoMoves = false
        firstTurnHasntMoved = false
        firstTurnHasMoved = false
    } else {
        firstTurnHasntMoved = true
        firstTurnHasMoved = false
        notFirstMoves = false
        notFirstNoMoves = false
    }

//     let bbc;
// let bc;
// if (darkMode === true) {
//     bbc = light
//     bc = dark
// } else {
//     bbc = dark
//     bc = light
// }



    return (
        <>
                <div style={{textAlign: "center", color: `${darkMode === true ? light : dark}`, fontSize: "1.2rem"}}>
            <p style={{textAlign: "center"}}>It's {turnId === Number(playerId) ? "your" : turnName + "s"} turn!</p>
            <Div>
            <p style={{alignSelf: "flex-start"}}>ORDER- &nbsp;</p>
                {players && players.length > 0 && players.map((player, i)=>{
                    return (
                        <p style={{}} key={player.id}><span style={turnId === player.id ? {textDecoration: "underline", fontWeight: "bold"} : {}}>{i + 1}: {playerName && playerName === player.name ? "You" : player.name}</span> {`(${player.tilesCount} tiles)`}&nbsp;</p>
                    )
                })}
            </Div>
            <Buttons>
           {

            // myTurn && needToRefresh ? 
            // <>
            // </>

            // : 


            myTurn && firstTurnHasMoved && !loading ? 
            <>
            {boardCopy.every(arr=>arr.length > 0) && <button onClick={addNewGroupToBoard}>CREATE NEW GROUP</button>}
            {(newGroupIds.length > 0 || moves > 0) && <button onClick={resetBoard}>{`RESET BOARD`}</button>}
            {boardCopy.every(arr=>arr.length > 0) && <button onClick={async e=>{
                    e.preventDefault()
                    e.stopPropagation()
                    setLoading(true)
                    endTurn()
            }}>END TURN</button>}
            </>

            :

            myTurn && firstTurnHasntMoved ? 
            <>
            {/* {loading && <p style={{color: `${darkMode === true ? light : dark}`}}>ONE MOMENT...</p>} */}
            {!loading && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={addNewGroupToBoard}>CREATE NEW GROUP</button>}
            {!loading && newGroupIds.length > 0 && boardCopy.every(arr=>arr.length > 0) && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={async e=>{
                e.preventDefault()
                e.stopPropagation()
                console.log(newGroupIds)
                let newGroupsVal = boardCopy.filter((arr, i)=>newGroupIds.includes(i)).map(arr=>getGroupValue(arr)).reduce((a,b)=>{
                    return a + b
                }, 0)
                if (newGroupsVal < 30) {
                    setMessage("Your first group(s) added to the board must have a total value of at least 30")
                    return
                }
                setLoading(true)
                setMessage("")
                setTimeout(()=>{
                    addFirstGroupHelperTwo()
                }, 500)
                
                }}>{`${"ADD FIRST GROUP(S) TO BOARD"}`}</button>}
            {!loading && newGroupIds.length > 0 && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={resetBoard}>CANCEL</button>}
            {!loading && newGroupIds.length === 0 && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={draw}>DRAW TILE AND END TURN</button>}
            </>

            :

            myTurn && notFirstMoves && !loading ? 
            <>
            {!withWildTime && !wildTime && boardCopy.every(arr=>arr.length > 0) && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={addNewGroupToBoard}>CREATE NEW GROUP</button>}
            {<button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={resetBoard}>RESET BOARD</button>}
            {!withWildTime && !wildTime && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={async e=>{
                    e.preventDefault()
                    e.stopPropagation()
                    endTurn()
                    
            }}>END TURN</button>}
            </>

            :

            myTurn && notFirstNoMoves && !loading ? 
            <>
            {boardCopy.every(arr=>arr.length > 0) && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={addNewGroupToBoard}>CREATE NEW GROUP</button>}
            {(moves > 0 || newGroupIds.length) > 0 && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={resetBoard}>RESET BOARD</button>}
            {newGroupIds.length === 0 && <button style={{color: `${darkMode === true ? dark : light}`, backgroundColor: `${darkMode === true ? light : dark}`}} onClick={draw}>DRAW TILE AND END TURN</button>}
            </>

            :

            <>
            </>
            
            }
            {/* {wildTime && <p style={{color: `${darkMode === true ? light : dark}`}}>SELECT ONE OF YOUR TILES TO REPLACE JOKER</p>}
            {withWildTime && <p style={{color: `${darkMode === true ? light : dark}`}}>ADD {ownTilesWithWild} OF YOUR OWN TILES TO NEW SET WITH JOKER</p>} */}
            
        </Buttons>
        {gameInfoStatus === "success" && gameInfo.deal_time === true && <div style={{display: "flex", justifyContent: "center"}}><Example color={darkMode ? light : dark} type={"spin"}/></div>}
        {loading && <div style={{display: "flex", justifyContent: "center"}}><Example color={darkMode ? light : dark} type={"spin"}/></div>}
        {/* {message && <p>{message}</p>} */}
    </div>
        {message && <p style={{fontSize: "1.3rem", textAlign: "center"}}>{message ? message : "MESSAGE AREA"}</p>}
        <TheBoard onDragExit={e=>{e.preventDefault(); e.stopPropagation();}} onDragEnd={e=>{e.preventDefault(); e.stopPropagation();}} onDragLeave={e=>{e.preventDefault(); e.stopPropagation();}} onDragEnter={e=>{e.preventDefault(); e.stopPropagation();}} onDragOver={e=>{e.preventDefault(); e.stopPropagation();}} style={boardInfo.length === 0 ? {textAlign: "default", backgroundColor: `${darkMode === true ? dark : light}`} : {textAlign: "default", backgroundColor: `${darkMode === true ? dark : light}`}}>
            
            {boardStatus === "success" && !myTurn && boardInfo.length > 0 && boardInfo.map((group, i)=>{
                return (
                    <BoardPlay stillInTurn={stillInTurn} darkMode={darkMode} dark={dark} light={light} tilesColored={tilesColored} width={width} myTurn={myTurn} wildTime={wildTime} withWildTime={withWildTime} newWildIndex={newWildIndex} oldWildIndex={oldWildIndex} submittingFirstGroup={submittingFirstGroup} setSubmittingFirstGroup={setSubmittingFirstGroup} resetBoard={resetBoard} newGroupIds={newGroupIds} setNewGroupIds={setNewGroupIds} movedOnce={movedOnce} getGroupValue={getGroupValue} boardIndex={i} dropHandler={dropHandler} isValidPlay={isValidPlay} boardCopy={boardCopy} setBoardCopy={setBoardCopy} takenFromHand={takenFromHand} dragFunctions={dragFunctions} theId={i} key={Math.random() * 10000} group={group}/>
                )
            })}
            {myTurn && boardCopy.length > 0 && boardCopy.map((group, i)=>{
                return (
                    <BoardPlay stillInTurn={stillInTurn} darkMode={darkMode} dark={dark} light={light} tilesColored={tilesColored} width={width} myTurn={myTurn} wildTime={wildTime} withWildTime={withWildTime} newWildIndex={newWildIndex} oldWildIndex={oldWildIndex} submittingFirstGroup={submittingFirstGroup} setSubmittingFirstGroup={setSubmittingFirstGroup} resetBoard={resetBoard} newGroupIds={newGroupIds} setNewGroupIds={setNewGroupIds} movedOnce={movedOnce} getGroupValue={getGroupValue} boardIndex={i} dropHandler={dropHandler} isValidPlay={isValidPlay} boardCopy={boardCopy} setBoardCopy={setBoardCopy} takenFromHand={takenFromHand} key={i} dragFunctions={dragFunctions} theId={i} group={group}/>
                )
            })}
            {/* {myTurn && newGroups.length > 0 && newGroups.map((randNum, i)=>{
                return (
                    <NewGroup boardIndex={(i + 1) + boardCopy.length} isValidPlay={isValidPlay} boardCopy={boardCopy} setBoardCopy={setBoardCopy} takenFromHand={takenFromHand} theId={randNum} dragFunctions={dragFunctions} key={randNum}/>
                )
            })} */}
        </TheBoard>
        </>
    )
}

export default Board
