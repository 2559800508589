import React, {useState} from 'react'
import styled from 'styled-components'

import MessageBoard from '../MessageBoard'
import Nav2 from './Nav2'
import Settings from './Settings'
import Logout from './Logout'

const Div = styled.div`
padding-bottom: 3%;
`



const Container = ({myTurn, light, dark, handAlign, setHandAlign, darkMode, setDarkMode, tilesColored, setTilesColored, handleChange, width, setWidth, dragFunctions}) => {
    
    const [selected, setSelected] = useState("");

    useState(()=>{
        setSelected("messages")
    }, [])




    return (
        <Div>
            <Nav2 darkMode={darkMode} selected={selected} setSelected={setSelected}/>
            {selected === "messages" ? 
            <MessageBoard myTurn={myTurn} dragFunctions={dragFunctions} />
            : selected === "settings" ? 
            <Settings handAlign={handAlign} setHandAlign={setHandAlign} darkMode={darkMode} setDarkMode={setDarkMode} tilesColored={tilesColored} setTilesColored={setTilesColored} handleChange={handleChange} width={width} setWidth={setWidth}/>
            : 
            <Logout dark={dark} light={light} darkMode={darkMode} setSelected={setSelected}/>
        }
        </Div>
    )
}

export default Container
