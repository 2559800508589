import axios from 'axios';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components'
import Example from './helpers/spinner.js'

const Div = styled.div`
border-radius: 5px;
margin: 4% auto;
`



const GameWaitingRoomPlayerInfo = ({startedBy, playersReady, dark, light, darkMode, playersDataStatus, playerInfo}) => {

    const [loading, setLoading] = useState(false)

    const {push} = useHistory();

const playerId = window.localStorage.getItem("playerId");
const gameId = window.localStorage.getItem("gameId")




const toggleReady = e => {
    setLoading(true)
    let newReady;
    if (playerInfo.ready === 1) {
        newReady = false;
    } else {
        newReady = true;
    }
    e.preventDefault();
    axios.put(`${process.env.REACT_APP_DB}/games/${gameId}/players/${playerId}`, {ready: newReady})
    .then(res=>{
        setTimeout(() => {
            setLoading(false)
        }, 500);
        
    })
    .catch(err=>{
        setLoading(false)
    })
}

const leaveGame = e => {
    e.preventDefault();
    setLoading(true)
    axios.delete(`${process.env.REACT_APP_DB}/games/game-players/${playerId}`)
    .then(res=>{
    
        window.localStorage.removeItem("playerId");
        window.localStorage.removeItem("gameId");
        setLoading(false)
        push('/lobby')
    })
    .catch(err=>{
        setLoading(false)
    })
}


    return (
        <Div style={playerInfo.id === Number(playerId) ? {border: `solid ${darkMode ? light : dark} 1px`, paddingBottom: "4%", paddingTop: "3%"} : {border: "none", paddingBottom: "4%", paddingTop: "3%"}}>
            {playerInfo.id === Number(playerId) ? "You are " : playerInfo.name + " is"}{playerInfo.ready === 0 ? " not ready" : " ready"}<br/>
            {!loading && playerInfo.id === Number(playerId) && <button style={{margin: "1%", padding: "2%", borderRadius: "5px", backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={toggleReady}>{playerInfo.ready === 1 ? "Not ready" : "Ready"}</button>}
            {!loading && playerInfo.id === Number(playerId) && <button style={{margin: "1%", padding: "2%", borderRadius: "5px", backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={leaveGame}>Leave Game</button>}
            {playersDataStatus === "success" && startedBy === Number(playerId) && playerInfo.id === startedBy && playersReady && !loading &&
            <>
            <p>All players are ready and waiting on you to start the game</p>
            <button style={{marginBottom: "4%", padding: "2%", borderRadius: "5px", backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={e=>{
                e.preventDefault();
                setLoading(true)
                axios.post(`${process.env.REACT_APP_DB}/games/${gameId}/start`, {})
                .then(res=>{
                    setLoading(false)
                    push(`/game/${gameId}`)
                })
                .catch(err=>{
                    setLoading(false)
                })
            }}>Start Game</button>
            
            </>}
            {loading && <div style={{display: "flex", justifyContent: "center"}}><Example color={darkMode ? light : dark} type={"spin"}/></div>}
        </Div>
    )
}

export default GameWaitingRoomPlayerInfo
