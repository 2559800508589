import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {useQuery} from 'react-query'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'

const P = styled.p`
display: inline-block;
font-size: 1.2rem;
margin: 1%;
text-decoration: none;
transition-property: text-decoration;
transition-duration: .5s;
transition-delay: .1s;
&:hover {
    cursor: pointer;
    text-decoration: underline;
}
`

const GameOver = () => {

    const playerId = window.localStorage.getItem("playerId")
    const {gameId} = useParams();
    const userId = window.localStorage.getItem("userId")

    const {push} = useHistory();

    const theGameId = window.localStorage.getItem("gameId")

    if (Number(theGameId) !== Number(gameId)) {
        push('/')
    }

    // const [gameStats, setGameStats] = useState([])
    // const [totalStats, setTotalStats] = useState([])
    const [numberOfPlayers, setNumberOfPlayers] = useState(null)

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DB}/fuckYou/${gameId}/${playerId}/end-game`)
        .then(res=>{
            setNumberOfPlayers(res.data.numPlayers)
        })
        .catch(err=>{
        })

        // axios.delete(`${process.env.REACT_APP_DB}/games/:gameId/delete/${playerId}`)
        // .then(res=>{
        //     window.localStorage.removeItem("playerId")
        // })
        // .catch(err=>{
        //     console.log(numberOfPlayers)
        // })
    }, )

    const getStats = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/play/${gameId}/game-over`)
        return res.json()
    }

    const {data: stats, status: statsStatus} = useQuery(`game${gameId}stats`, getStats, {refetchInterval: 500})


// let pointsPerGame;

// if (statsStatus === "success") {
//   pointsPerGame = stats.totalStats.map(obj=>({name: obj.name, ppg: obj.points / obj.games}))
//   console.log(stats)
// }

console.log(userId)
// const playAgain = e => {
//     e.preventDefault()
//     e.stopPropagation()
//     push(`/game-waiting-room/${gameId}`)
// }

const backToLobby = async e => {
    e.preventDefault()
    e.stopPropagation()
    axios.delete(`${process.env.REACT_APP_DB}/games/delete/${playerId}`)
    .then(res=>{
        // window.localStorage.removeItem("userId");
        window.localStorage.removeItem("playerId")
        window.localStorage.removeItem("gameId")
    })
    .catch(err=>{
        console.log(numberOfPlayers)
    })

    push('/lobby')
}

// const logout = e => {
//     e.preventDefault()
//     e.stopPropagation()
//     window.localStorage.removeItem("userId");
//     window.localStorage.removeItem("handWidth")
//     window.localStorage.removeItem("handTiles")
//     window.localStorage.removeItem("groupSize")
//     window.localStorage.removeItem("appearance")
//     window.localStorage.removeItem("tilesColored")
//     push('/')
// }

    return (
        <div style={{width: "100vw",textAlign: "center", margin: "-1.3%", minHeight: "100vh", paddingBottom: ".1%"}}>
         {statsStatus === "success" &&
         <>
           <div>
                <h3 style={{paddingTop: "1%", textDecoration: "underline"}}>RESULTS</h3>
                {stats.playerStats.sort((a,b)=>a.points > b.points ? 1 : a.points < b.points ? -1 : 0).map((obj, i)=>{
                    if (i === 0) {
                        return <p>WINNER: {obj.name}</p>
                    } else {
                        let aft;
                        switch(i + 1) {
                            case 2:
                                aft = "nd";
                                break;
                            case 3:
                                aft = "rd"
                                break;
                            default:
                                aft = "th"
                        }
                        return <p>{i+1}{aft}: {obj.name} {`(-${obj.points} points)`}</p>
                    }
                })}
            </div>
            {/* <div>
                <h3 style={{marginTop: "3%", textDecoration: "underline"}}>STATS</h3>
                <p>TOTAL WINS</p>
                {stats.totalStats.sort((a,b)=>a.wins > b.wins ? -1 : a.wins < b.wins ? 1 : 0).map((obj, i)=>{
                    return (
                        <p>{i + 1}: {obj.name} {`(${obj.wins})`}</p>
                    )
                })}
                <p>POINTS PER GAME</p>
                {pointsPerGame && pointsPerGame.sort((a,b)=>a.ppg < b.ppg ? -1 : a.ppg > b.ppg ? 1 : 0).map((obj, i)=>{
                    return (
                        <p>{i + 1}: {obj.name} {`(${obj.ppg === 0 ? "" : "-"}${obj.ppg})`}</p>
                    )
                })}
            </div> */}
            <div>

            </div></>}
            {/* <P onClick={playAgain}>PLAY AGAIN</P> */}
            <P onClick={backToLobby}>BACK TO LOBBY</P>
            {/* <P onClick={logout}>LOG OUT</P> */}
        </div>
    )
}

export default GameOver
