import React from 'react'
import styled from 'styled-components'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

const EmojiContainer = styled.div`
transform: scale(.75);
margin-top: -5.6%;
margin-bottom: -6%;
`

const Emojis = ({handleSubmit, handleChange, message, setMessage}) => {


    // const [emojis, setEmojis] = useState([]);

    // useEffect(()=>{

    //     let arr = [];

    //     for (let i = 129296; i <= 129327; i++) {
    //         let e = "&#" + i + ";"
    //         arr.push(e)
    //     }

    //     arr.push("&#129330;")
    //     arr.push("&#129488;")
    //     arr.push("&#128591;")

    //     for (let i = 128064; i <= 128080; i++) {
    //         let e = "&#" + i + ";"
    //         arr.push(e)
    //     }
        
    //     arr.push("&#128175;")

    //     for (let i = 128169; i <= 128170; i++) {
    //         let e = "&#" + i + ";"
    //         arr.push(e)
    //     }

    //     for (let i = 128584; i <= 128586; i++) {
    //         let e = "&#" + i + ";"
    //         arr.push(e)
    //     }

    //     for (let i = 128000; i <= 128063; i++) {
    //         let e = "&#" + i + ";"
    //         arr.push(e)
    //     }

    //     for (let i = 129408; i <= 129431; i++) {
    //         let e = 
    //         arr.push(e)
    //     }
    //     setEmojis([...arr])
    // }, [])

    // console.log(emojis)

    const handleEmojiClick = (emoji) => {
        setMessage(message + emoji.native)
    }

    return (
        <EmojiContainer>
            <Picker native="true" onSelect={handleEmojiClick}/>

        </EmojiContainer>
    )
}

export default Emojis
