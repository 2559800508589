import React from 'react'
import styled from 'styled-components'

const TypeMessageArea = styled.div`
margin-top: 1%;
form {
    button {
        margin-top: 1%;
        margin-right: 1%;
    }
    p {
        display: "inline"
    }
}
`

const TypeMessage = ({darkMode, light, dark, emojisToggled, toggleEmojis, setMessage, handleSubmit, handleChange, message}) => {





    return (
        <TypeMessageArea>
            <form>
                <label htmlFor="messagetext">Chat<br/>
                    <input
                    size="40"
                    type="text"
                    value={message}
                    onChange={handleChange}
                    name="messagetext"/>
                </label><br/>
                <button style={{padding: "2%", borderRadius: "5px",backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={handleSubmit}>Send</button>
                <button style={{padding: "2%", borderRadius: "5px",backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={toggleEmojis}>{emojisToggled ? "Hide emojis" : "View emojis"}</button>
                <p style={message.length > 140 ? {color: "red"} : {color: `${darkMode ? light : dark}`}}>{message.length}/140 characters</p>
            </form>
            
        </TypeMessageArea>
    )
}

export default TypeMessage
