import React, {useState} from 'react'
import styled from 'styled-components'

import BoardPiece from './BoardPiece'
import BoardPlayGap from './BoardPlayGap'
import axios from 'axios'

const TheBoardPlay = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: nowrap;
margin: 1% 2%;
border-radius: 5px;
`


const BoardPlay = ({stillInTurn, newGroupIds, darkMode, dark, light, tilesColored, width, myTurn, wildTime, withWildTime, newWildIndex, oldWildIndex, submittingFirstGroup, setSubmittingFirstGroup, movedOnce, isValidPlay, theId, group, dragFunctions, takenFromHand, setBoardCopy, boardCopy, dropHandler, boardIndex, getGroupValue}) => {
    const [hoveredIndex, setHoveredIndex] = useState(null)
 

    const gameId = window.localStorage.getItem("gameId")
    const playerId = window.localStorage.getItem("playerId")



if (submittingFirstGroup === true) {
        if (getGroupValue(group) < 30) {
            setSubmittingFirstGroup(false)
            return
        }
        else {
        const playerPiecesToDelete = takenFromHand.map(str=>{
            const num = Number(str.split("h")[0])
            return num
        })
        const postedObj = {playerPiecesToDelete, newBoard: [...boardCopy]}
        axios.post(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/first-move`, postedObj)
        .then(res=>{
            window.location.reload(true)
            setSubmittingFirstGroup(false)
        })
        .catch(err=>{
            setSubmittingFirstGroup(false)
        })
    }
    
}

// const handleRemoveSet = e => {
//     e.preventDefault()
//     e.stopPropagation()
//     const newBoardCopy = [...boardCopy.filter((arr, i)=>i !== theId)]
//     setBoardCopy([...newBoardCopy])
// }


let theHeight;
let theFont
let theWidth;
let tileWidth;

let theOpacity;

if (wildTime) {
    if (oldWildIndex === theId) {
        theOpacity = "1"
    } else {
        theOpacity = ".3"
    }
} else if (withWildTime) {
    if (newWildIndex === theId) {
        theOpacity = "1"
    } else {
        theOpacity = ".3"
    }
} else {
    theOpacity = "1"
}

// 0
if (group.length === 0) {
    if (width.groupSize === 1) {
        theHeight = "5.7vh"
        theWidth = "5%"
        if (width.groupTiles === 1) {
            tileWidth = "10%"
            theFont = "1rem"
        } else if (width.groupTiles === 3) {
            tileWidth = "12%"
            theFont = "1.2rem"
        } else if (width.groupTiles === 5) {
            tileWidth = "14%"
            theFont = "1.9rem"
        }
    } else if (width.groupSize === 3) {
        theHeight = "7.35"
        theWidth = "10%"
        if (width.groupTiles === 1) {
            tileWidth = "12%"
            theFont = "1.2rem"
        } else if (width.groupTiles === 3) {
            tileWidth = "14%"
            theFont = "1.6rem"
        } else if (width.groupTiles === 5) {
            tileWidth = "15%"
            theFont = "1.9rem"
        }
    } else if (width.groupSize === 5) {
        theHeight = "10.9vh"
        theWidth = "12%"
        if (width.groupTiles === 1) {
            tileWidth = "12%"
            theFont = "1.2rem"
        } else if (width.groupTiles === 3) {
            tileWidth = "14%"
            theFont = "1.6rem"
        } else if (width.groupTiles === 5) {
            tileWidth = "15%"
            theFont = "1.7rem"
        }
    }
} 


if (width.groupSize === 1) {
    theHeight = "5.7vh"
    theFont = ".7rem"
    if (group.length === 1) {
        theWidth = "5%"
        tileWidth = "35%"
    } else if (group.length === 2) {
        theWidth = "7%"
        tileWidth = "40%"
    } else if (group.length === 3) {
        theWidth = "10%"
        tileWidth = "42%"
    } else if (group.length === 4) {
        theWidth = "13%"
        tileWidth = "40%"
    } else if (group.length === 5) {
        theWidth = "15%"
        tileWidth = "40%"
    } else if (group.length === 6) {
        theWidth = "17%"
        tileWidth = "40%"
    } else if (group.length === 7) {
        theWidth = "21%"
        tileWidth = "40%"
        theFont = ".9rem"
    } else if (group.length === 8) {
        theWidth = "24%"
        tileWidth = "40%"
    } else if (group.length === 9) {
        theWidth = "28%"
        tileWidth = "40%"
    } else if (group.length === 10) {
        theWidth = "30%"
        tileWidth = "40%"
    } else if (group.length === 11) {
        theWidth = "35%"
        tileWidth = "40%"
    } else if (group.length > 11) {
        theWidth = "40%"
        tileWidth = "40%"
    }
} else if (width.groupSize === 3) {
    theHeight = "7.35vh"
    theFont = "1.0rem"
    if (group.length === 1) {
        theWidth = "6%"
        tileWidth = "43%"
    } else if (group.length === 2) {
        theWidth = "10%"
        tileWidth = "40%"
    } else if (group.length === 3) {
        theWidth = "14%"
        tileWidth = "40%"
    } else if (group.length === 4) {
        theWidth = "19%"
        tileWidth = "40%"
    } else if (group.length === 5) {
        theWidth = "23%"
        tileWidth = "40%"
    } else if (group.length === 6) {
        theWidth = "27%"
        tileWidth = "40%"
    } else if (group.length === 7) {
        theWidth = "31%"
        tileWidth = "40%"
    } else if (group.length === 8) {
        theWidth = "36%"
        tileWidth = "40%"
    } else if (group.length === 9) {
        theWidth = "41%"
        tileWidth = "40%"
    } else if (group.length === 10) {
        theWidth = "45%"
        tileWidth = "40%"
    } else if (group.length === 11) {
        theWidth = "49%"
        tileWidth = "40%"
    } else if (group.length > 11) {
        theWidth = "54%"
        tileWidth = "40%"
    }
} else if (width.groupSize === 5) {
    theHeight = "10.9vh"
    theFont = "1.5rem"
    if (group.length === 1) {
        theWidth = "10%"
        tileWidth = "36%"
    } else if (group.length === 2) {
        theWidth = "14%"
        tileWidth = "40%"
    } else if (group.length === 3) {
        theWidth = "21%"
        tileWidth = "40%"
    } else if (group.length === 4) {
        theWidth = "26%"
        tileWidth = "40%"
    } else if (group.length === 5) {
        theWidth = "33%"
        tileWidth = "40%"
    } else if (group.length === 6) {
        theWidth = "40%"
        tileWidth = "40%"
    } else if (group.length === 7) {
        theWidth = "48%"
        tileWidth = "40%"
    } else if (group.length === 8) {
        theWidth = "56%"
        tileWidth = "40%"
    } else if (group.length === 9) {
        theWidth = "63%"
        tileWidth = "40%"
    } else if (group.length === 10) {
        theWidth = "70%"
        tileWidth = "40%"
    } else if (group.length === 11) {
        theWidth = "80%"
        tileWidth = "40%"
    } else if (group.length > 11) {
        theWidth = "90%"
        tileWidth = "40%"
    }
} 



    return (
        <TheBoardPlay  style={{height: theHeight, width: theWidth, fontSize: theFont, border: `solid ${darkMode ? light : dark} 3px`, opacity: theOpacity, margin: "auto, 1%"}} id={theId} onDragExit={e=>{e.preventDefault(); e.stopPropagation();}} onDragLeave={e=>{e.preventDefault(); e.stopPropagation();}} onDragEnter={e=>{e.preventDefault(); e.stopPropagation();}}>
            {group.length === 1 ?                         <>
                        <BoardPlayGap width={width} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode} theFont={theFont} oldWildIndex={oldWildIndex} newWildIndex={newWildIndex} wildTime={wildTime} withWildTime={withWildTime} myTurn={myTurn} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} selected={hoveredIndex === Number(0)} group={group} boardIndex={Number(boardIndex)} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(0)} key={Math.random() * 10000}/>
                        <BoardPiece newGroupIds={newGroupIds} boardIndex={boardIndex} group={group} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} tileWidth={tileWidth} tilesColored={tilesColored} width={width} movedOnce={movedOnce} dragFunctions={dragFunctions} theId={Number(theId)} key={boardCopy[boardIndex][0].id} pieceInfo={boardCopy[boardIndex][0]}/>
                        <BoardPlayGap width={width} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} oldWildIndex={oldWildIndex} newWildIndex={newWildIndex} wildTime={wildTime} withWildTime={withWildTime} myTurn={myTurn} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} selected={hoveredIndex === Number(1)} group={group} boardIndex={Number(boardIndex)} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(1)} key={Math.random() * 10000}/>
                        </> 
                        :
            group.length === 0 ?                        
            <BoardPlayGap width={width} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode} theFont={theFont} oldWildIndex={oldWildIndex} newWildIndex={newWildIndex} wildTime={wildTime} withWildTime={withWildTime} myTurn={myTurn} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} selected={hoveredIndex === Number(0)} group={group} boardIndex={boardIndex} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(0)} key={Math.random() * 10000}/>
             :
             group.length === 2 ?
             group.map((piece, i)=>{
                 if (i === 0) {
                     return (
                        <>
                        <BoardPlayGap width={width} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} myTurn={myTurn} selected={hoveredIndex === Number(i)} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} group={group} boardIndex={boardIndex} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(i)} key={Math.random() * 1000000}/>
                        <BoardPiece newGroupIds={newGroupIds} boardIndex={boardIndex} group={group} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} tileWidth={tileWidth} tilesColored={tilesColored} width={width} movedOnce={movedOnce} dragFunctions={dragFunctions} theId={theId} key={piece.id} pieceInfo={piece}/>
                        </>
                     )
                 } else {
                     return (
                         <>
                        <BoardPlayGap width={width} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} oldWildIndex={oldWildIndex} newWildIndex={newWildIndex} wildTime={wildTime} withWildTime={withWildTime} myTurn={myTurn} selected={hoveredIndex === Number(i)} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} group={group} boardIndex={boardIndex} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(i)} key={Math.random() * 1000000}/>
                        <BoardPiece newGroupIds={newGroupIds} boardIndex={boardIndex} group={group} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} tileWidth={tileWidth} tilesColored={tilesColored} width={width} movedOnce={movedOnce} dragFunctions={dragFunctions} theId={theId} key={piece.id} pieceInfo={piece}/>
                        <BoardPlayGap width={width} stillInTurn={stillInTurn}dark={dark} light={light} darkMode={darkMode}  theFont={theFont} oldWildIndex={oldWildIndex} newWildIndex={newWildIndex} wildTime={wildTime} withWildTime={withWildTime} myTurn={myTurn} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} selected={hoveredIndex === i + 1} group={group} boardIndex={boardIndex} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(i + 1)} key={Math.random() * 10000}/>
                        </>
                     )
                 }
             })
            
            : 
            group.map((piece, i)=>{
                if (i < group.length - 1) {
                return (
                    <>
                    <BoardPlayGap width={width} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} myTurn={myTurn} selected={hoveredIndex === Number(i)} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} group={group} boardIndex={boardIndex} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(i)} key={Math.random() * 1000000}/>
                    <BoardPiece newGroupIds={newGroupIds} boardIndex={boardIndex} group={group} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} tileWidth={tileWidth} tilesColored={tilesColored} width={width} movedOnce={movedOnce} dragFunctions={dragFunctions} theId={theId} key={piece.id} pieceInfo={piece}/>
                    </>
                )
                } else {
                    return (
                        <>
                            <BoardPlayGap width={width} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} oldWildIndex={oldWildIndex} newWildIndex={newWildIndex} wildTime={wildTime} withWildTime={withWildTime} myTurn={myTurn} selected={hoveredIndex === Number(i)} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} group={group} boardIndex={boardIndex} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(i)} key={Math.random() * 1000000}/>
                            <BoardPiece newGroupIds={newGroupIds} boardIndex={boardIndex} group={group} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode}  theFont={theFont} tileWidth={tileWidth} tilesColored={tilesColored} width={width} movedOnce={movedOnce} dragFunctions={dragFunctions} theId={theId} key={piece.id} pieceInfo={piece}/>
                            <BoardPlayGap width={width} stillInTurn={stillInTurn}dark={dark} light={light} darkMode={darkMode}  theFont={theFont} oldWildIndex={oldWildIndex} newWildIndex={newWildIndex} wildTime={wildTime} withWildTime={withWildTime} myTurn={myTurn} setHoveredIndex={setHoveredIndex} movedOnce={movedOnce} selected={hoveredIndex === i + 1} group={group} boardIndex={boardIndex} dropHandler={dropHandler} dragFunctions={dragFunctions} index={Number(i + 1)} key={Math.random() * 10000}/>
                        </>
                    )
                    }
            })}
        </TheBoardPlay>
    )
    
}

export default BoardPlay
