import React from 'react'
import styled from 'styled-components'

import Piece from './Piece';

const PieceContainer = styled.div`
margin: 0 auto 9% auto;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: flex-start;
border-radius: 5px;
`



const Hand = ({handAlign, dark, light, darkMode, boardCopy, tilesColored, buttonToggled, width, takenFromHand, pieces, dragFunctions, handPiecesOnBoard}) => {

// let marginBottom;
// if (width.handTiles === 1) {
//     if (width.handWidth <= 50) {
//         if (boardCopy.length === 0) {
//             marginBottom = "2.2%"
//         }else {
//             marginBottom = "2.6%"
//         }
//     } else if (width.handWidth >= 60) {
//         if (boardCopy.length === 0) {
//             marginBottom = "5.8%"
//         } else {
//             marginBottom = "6.3%"
//         }

//     }
// } else if (width.handTiles === 3) {

//     if (width.handWidth <= 45) {
//         if (boardCopy.length === 0) {
//             marginBottom = "4%"
//         } else {
//             marginBottom = "5.3%"
//         }
//     } else if (width.handWidth === 50) {
//         marginBottom = "1.5%"
//     } else if (width.handWidth >= 55 && width.handWidth < 70) {
//         marginBottom = "2%"
//     } else if (width.handWidth >= 70) {
//         if (width.groupSize === 3) {
//             marginBottom = "10%"
//         } else {
//             marginBottom = "5%"
//         }

//     }
// } else if (width.handTiles === 5) {
//     if (boardCopy.length === 0) {
//         if (width.handWidth <= 45) {
//             marginBottom = "5.2%"
//         } else if (width.handWidth === 50) {
//             marginBottom = ".5%"
//         }
//     } else {
//         if (width.handWidth <= 45) {
//             marginBottom = "5.3%"
//         } else if (width.handWidth === 50) {
//             marginBottom = ".5%"
//         }
//     }
// }
    const displayedPieces = pieces.filter(piece=>!takenFromHand.includes(piece.id + "hand"))

    return (
        <div style={{borderBottom: `solid ${darkMode ? light : dark} 3px`}}>
        <PieceContainer style={boardCopy.length === 0 ? {justifyContent: `${handAlign === "left" ? "flex-start" : handAlign === "right" ? "flex-end" : "center"}`,  border: `solid ${darkMode === true ? light : dark} 5px`, width: `${width.handWidth - 2}%`} : {justifyContent: `${handAlign === "left" ? "flex-start" : handAlign === "right" ? "flex-end" : "center"}`, width: `${width.handWidth - 2}%`, border: `solid ${darkMode === true ? light : dark} 5px`}}>
           {!buttonToggled && displayedPieces.map(piece=>{
               return (
                   <Piece tilesColored={tilesColored} pieces={pieces} width={width} handPiecesOnBoard={handPiecesOnBoard} id={piece.id} dragFunctions={dragFunctions} key={piece.id} piece={piece}/>
               )
           })} 

        </PieceContainer>
        </div>
    )
}

export default Hand
