import React, {useState} from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import Example from '../helpers/spinner.js'


const P = styled.p`
&:hover {
    cursor: pointer;
}
`

const Div = styled.div`
width: 50%;
margin: 4% auto;
text-align: center;
div {
    p {
        margin: auto 2%;
        display: inline-block
    }
    p:hover {
        cursor: pointer;
    }
}
`

const Logout = ({darkMode, light, dark, setSelected}) => {

    const [loading, setLoading] = useState(false)

    const {push} = useHistory();

    const gameId = window.localStorage.getItem("gameId")

    const userId = window.localStorage.getItem("userId")

    const handleYes = async e => {
        try {
        e.preventDefault()
        e.stopPropagation()
        setLoading(true)
        await axios.get(`${process.env.REACT_APP_DB}/games/${gameId}/pre-end`)
        axios.delete(`${process.env.REACT_APP_DB}/games/delete/${userId}`)
        .then(res=>{
            window.localStorage.removeItem("userId");
            window.localStorage.removeItem("playerId")
            window.localStorage.removeItem("gameId")
            setLoading(false)
            push('/')
        })
        .catch(err=>{
            setLoading(false)
        })
    }catch(err) {
    }

    }

    const handleNo = e => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(false)
        setSelected("messages")
    }


    return (
        <Div>
            <p style={{fontSize: "1.2rem"}}>THIS WILL END THE GAME FOR ALL PARTICIPANTS. ARE YOU SURE?</p>
            {!loading ? <div>
                <P onClick={handleYes}>YES</P>
                <P onClick={handleNo}>NO</P>
            </div> : 
            <div style={{display: "flex", justifyContent: "center"}}><Example color={darkMode ? light : dark} type={"spin"}/></div>
            }
        </Div>
    )
}

export default Logout
