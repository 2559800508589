import React from 'react'



/* have styles dependent on STATE... 
 const [playerInfo, setPlayerInfo] = (useState({})
 const [boardStyle, setBoardStyle] = (useState({}))

useEffect(()=>{

               let turnId;
                let turnName;
                let players;
                let myTurn;
                let seen; etc...

                const playerObject = {turnId, turnName, players, etc...}
                const boardStyleObject = {
                    getSelected(index) {
                        return index is whatever
                    },
                    wow: this,
                    whatever () {
                        big time
                    }, 
                    yeah
                }

                setPlayerInfo(playerObject)
                setPlayerStyle(boardStyleObject)

                etc

                and if it's your turn you want to be refetching data about every second but if it's not then about every 3-5 seconds



}, [])

*/



const BoardPlayGap = ({width, stillInTurn, dark, light, darkMode, theFont, oldWildIndex, newWildIndex, wildTime, withWildTime, myTurn, selected, setHoveredIndex, index, dragFunctions, boardIndex, group}) => {
    

    let fontSize;
    if (group.length === 0) {
        fontSize = "1.1rem"
    } else {
        fontSize = theFont
    }

    const handleDragOver = e => {
        e.preventDefault()
        e.stopPropagation()
        if (myTurn) {
        if (wildTime) {
            if (oldWildIndex === boardIndex) {
                setHoveredIndex(index)
                return
            }
        } else if (withWildTime) {
            if (newWildIndex === boardIndex) {
                setHoveredIndex(index)
                return
            }
        } else {
            setHoveredIndex(index)
        }
        }
        // setColor('white')
        // console.log(e.target)

    }

    const handleDragLeave = e => {
        e.preventDefault()
        e.stopPropagation()
        
            setHoveredIndex(null)
        // setColor('white')
        // setWidth(8)
    }

    const handleDrop = e => {
        e.preventDefault()
        e.stopPropagation()
        const pieceData = e.dataTransfer.getData("text/plain");
        let newGroupId = Number(e.target.id)
        let newIndex = index
        if (e.target.nodeName === "SECTION") {
            if (e.target.className === "0") {
                newIndex = Number(0)
            } else {
                newIndex = Number(e.target.className)
            }
        }
        dragFunctions.handleDropInBoard(pieceData, newGroupId, newIndex)
        if (myTurn) {
            setHoveredIndex(null)
        }

    }

let selectedWidth;
let notSelectedWidth;
if (group.length === 0) {
    selectedWidth = "100%"
    notSelectedWidth = "100%"
} else {
    selectedWidth = "40%"
    notSelectedWidth = "23%"
}

    

    return (
        <section className={index} id={boardIndex} onDrop={handleDrop} style={selected === true ? {textAlign: "center", fontSize, margin: "1% 0%", backgroundColor: `${darkMode === true ? light : dark}`, width: selectedWidth} : {textAlign: "center", fontSize, margin: "1% 0%", backgroundColor: `${darkMode === true ? dark : light}`, width: notSelectedWidth}} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDragExit={handleDragLeave}>
            
        </section>
    )
}



export default BoardPlayGap