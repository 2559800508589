import React, {useState} from 'react';
import {useQuery} from 'react-query';
import styled from 'styled-components';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import Example from './helpers/spinner'


const Game = styled.div`
width: 60%;
margin: 4% auto;
border-radius: 5px;
padding-bottom: 2%;
 button {
     margin: 3% auto;
     
 }
`

const LobbyGame = ({dark, light, darkMode, gameId}) => {

    const [loading, setLoading] = useState(false);

    const {push} = useHistory();

    const userId = window.localStorage.getItem('userId')

    if (!userId) {
        push('/')
    }

    const joinGame = e => {
        e.preventDefault();
        setLoading(true)
        axios.post(`${process.env.REACT_APP_DB}/games/${gameId}/players`, {userId: Number(userId)})
        .then(res=>{
            setLoading(false)
            window.localStorage.setItem("playerId", res.data.id);
            window.localStorage.setItem("gameId", res.data.game_id)
            push(`/game-waiting-room/${gameId}`)
        })
        .catch(err=>{
            setLoading(false)
        })
    }


    const getGamePlayers = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/games/${gameId}/players`)
        return res.json()
    }

    const {data: gamePlayers, status: gamePlayersStatus} = useQuery(`game${gameId}PlayersData`, getGamePlayers, {refetchInterval: 5000})

    return (
        <Game dark={dark} light={light} darkMode={darkMode} style={{border: `solid ${darkMode ? light : dark} 2px`}}>
            <p style={{fontWeight: "bold"}}>Game {gameId} Players:</p>
            {gamePlayersStatus === "success" && gamePlayers.map(player=>{
                return <p key={player.id}>{player.name}</p>
            })}
            {!loading ? <button style={{padding: "2%", borderRadius: "5px",backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={joinGame}>Join</button> : 
            <div style={{display: "flex", justifyContent: "center"}}><Example color={darkMode ? light : dark} type={"spin"}/></div>
            }
        </Game>
    )
}

export default LobbyGame
