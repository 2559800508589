import React, {useState} from 'react';
import {useQuery} from 'react-query'
import axios from 'axios'
import {useHistory} from 'react-router-dom'

import Example from './helpers/spinner'

import styled from 'styled-components';


import LobbyGame from './LobbyGame';

const LobbyGameContainer = styled.div`
display: flex;
flex-direction: column;
width: 40%;
margin: auto;
text-align: center;
button {
    width: 20%;
    margin: auto;
}
min-height: 100vh;
padding-bottom: 2%;
`




const Lobby = ({dark, light, darkMode}) => {
    const [loading, setLoading] = useState(false)

    // add conditional to check what games they're a part of that are still "active" and give them an option to go to one of those games

    const {push} = useHistory();

    const userId = window.localStorage.getItem("userId");
    if (!userId) {
        push('/')
    }
    const getLobbyGames = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/games/lobby`)
        return res.json()
    }

    const {data: lobbyGames, status: lobbyGamesStatus} = useQuery('lobbyGamesData', getLobbyGames, {refetchInterval: 10000})

    const startNewGame = e => {
        setLoading(true)
        let gameId;
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_DB}/games/lobby`, {userId: Number(userId)})
        .then(res=>{
            gameId = res.data.newGameInfo.id;
            window.localStorage.setItem("gameId", gameId);
            window.localStorage.setItem("playerId", res.data.newGamePlayer)
            setLoading(false)
            push(`/game-waiting-room/${res.data.newGameInfo.id}`)
        })
        .catch(err=>{
            setLoading(false)
        })
        // axios.post(`${process.env.REACT_APP_DB}/games/${gameId}/players`, {playerId: Number(userId)})
        // .then(res=>{
        //     window.localStorage.setItem("playerId", res.data.id)
        //     push(`/game-waiting-room/${gameId}`)
        // })
        // .catch(err=>{
        //     console.log(err)
        // })
    }

    const logout = e => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(true)
        axios.delete(`${process.env.REACT_APP_DB}/games/delete/${userId}`)
        .then(res=>{
            
            window.localStorage.removeItem("userId");
            window.localStorage.removeItem("playerId")
            window.localStorage.removeItem("gameId")
            setLoading(false)
            push('/')
        })
        .catch(err=>{
            setLoading(false)
            push('/')
        })
    }


    return (
        <LobbyGameContainer>
            <h2 style={{textDecoration: "underline"}}>LOBBY</h2>
            {lobbyGamesStatus === "success" && lobbyGames.length === 0 &&
            <p>THERE ARE CURRENTLY NO GAMES TO JOIN</p>
            }
            <div>
            {!loading ? <button style={{fontSize: ".73rem", padding: "2%", borderRadius: "5px",marginTop: "3%", backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={startNewGame}>Start New Game</button>:
            <div style={{display: "flex", justifyContent: "center"}}><Example color={darkMode ? light : dark} type={"spin"}/></div>
            }
            <br/><br/>
            </div>
            {lobbyGamesStatus === "success" && lobbyGames.length > 0 && 
            <div>
                or<br/>
                <p style={{fontSize: "1.1rem"}}>Join Existing Game</p>
            {lobbyGames.map(game=>{
                return (
                    <LobbyGame dark={dark} light={light} darkMode={darkMode} key={game.id} gameId={game.id}/>
                )
            })}
            </div>}
            <button style={{padding: "2%", borderRadius: "5px",backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={logout}>Exit Lobby</button>
        </LobbyGameContainer>
    )
}

export default Lobby
