import React from 'react'
import styled from 'styled-components'

const Nav = styled.nav`
display: flex;
justify-content: center;
flex-direction: row;
padding: 0;
margin: 1.5% auto;
font-size: "12rem";
text-align: center;
p {
    margin: auto 6%;
}

p:hover {
    cursor: pointer;
}

`

const Nav2 = ({selected, setSelected, darkMode}) => {

    const handleClick = e => {
        e.preventDefault()
        e.stopPropagation()
        setSelected(e.target.id)
    }


    return (
        <Nav>
            <p style={selected === "settings" ? {fontSize: "1.3rem", fontWeight: "bold", textDecoration: "underline", opacity: "1"} : {fontSize: "1.2rem",opacity: `${darkMode ? ".6" : ".8"}`}} id="settings" onClick={handleClick}>DISPLAY SETTINGS</p>
            <p style={selected === "messages" ? {fontSize: "1.2rem", fontWeight: "bold", textDecoration: "underline", opacity: "1"} : {fontSize: "1.2rem",opacity: `${darkMode ? ".6" : ".8"}`}}id="messages" onClick={handleClick}>MESSAGES</p>
            <p style={selected === "logout" ? {fontWeight: "bold", textDecoration: "underline", opacity: "1"  } : {fontSize: "1.2rem",opacity: `${darkMode ? ".6" : ".8"}`}} id="logout" onClick={handleClick}>LEAVE GAME</p>
        </Nav>
    )
}

export default Nav2
