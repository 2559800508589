import React from 'react'
import styled from 'styled-components'

const Flexbox = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: flex-start;
width: 60%;
margin: auto;
text-align: center;
div {
    p {
        text-decoration: underline
    }
}
`


const Settings = ({handAlign, setHandAlign, darkMode, setDarkMode, tilesColored, setTilesColored, handleChange, width, setWidth}) => {

    const handleDarkModeChange = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value === "Dark") {
            window.localStorage.setItem("appearance", "dark")
            setDarkMode(true)
        } else {
            window.localStorage.setItem("appearance", "light")
            setDarkMode(false)
        }
    }

    const handleTilesColoredChange = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value === "colored") {
            window.localStorage.setItem("tilesColored", "colored")
            setTilesColored(true)
        } else if (e.target.value === "white") {
            window.localStorage.setItem("tilesColored", "white")
            setTilesColored(false)
        }
    }

    const handleAlignmentChange = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value === "Left") {
            window.localStorage.setItem("handAlign", "left")
            setHandAlign("left")
        } else if (e.target.value === "Center") {
            window.localStorage.setItem("handAlign", "center") 
            setHandAlign("center")
            
        } else if (e.target.value === "Right") {
            window.localStorage.setItem("handAlign", "right")
            setHandAlign("right")
        }
    }

    return (
        <Flexbox>
            <div>
                <p>GENERAL</p>
                <label htmlFor="darkMode">DISPLAY MODE &nbsp;&nbsp;
                <select 
                name="darkMode" value={darkMode === true ? "Dark" : "Light"} onChange={handleDarkModeChange}
                >
                    <option value="Dark">Dark</option>
                    <option value="Light">Light</option>
                </select>
            </label>
            <br/><br/>
            <label htmlFor="tilesColored">TILE BACKGROUND &nbsp;&nbsp;
                <select 
                name="tilesColored" value={tilesColored ? "colored" : "white"} onChange={handleTilesColoredChange}
                >
                    <option value="colored">Colored</option>
                    <option value="white">White</option>
                </select>
            </label>
            </div>
            <div>
                <p>BOARD</p>
                <label htmlFor="groupSize">GROUP AREA SIZE<br/>
                    <input
                    value={width.groupSize}
                    onChange={handleChange}
                    type="range"
                    name="groupSize"
                    min="1"
                    max="5"
                    step="2"/>
            </label>
            <br/>
            </div>

            <div>
                <p>HAND</p>
                <label htmlFor="handWidth">HAND PIECES AREA WIDTH<br/>
                    <input
                    value={width.handWidth}
                    onChange={handleChange}
                    type="range"
                    name="handWidth"
                    min="40"
                    max="95"
                    step="5"/>
            </label>
                <br/><br/>
            <label htmlFor="handTiles">HAND TILES SIZE<br/>
                    <input
                    value={width.handTiles}
                    onChange={handleChange}
                    type="range"
                    name="handTiles"
                    min="1"
                    max="5"
                    step="2" />
            </label><br/><br/>
            <label htmlFor="handAlign">HAND TILE LAYOUT &nbsp;&nbsp;
            <select 
                name="handAlign" value={handAlign === "left" ? "Left" : handAlign === "center" ? "Center" : "Right"} onChange={handleAlignmentChange}
                >
                    <option value="Left">Left</option>
                    <option value="Center">Center</option>
                    <option value="Right">Right</option>
                </select>
            </label>
            <br/>
            </div>

        </Flexbox>
    )
   
}

export default Settings
