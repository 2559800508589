import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import styled, {keyframes} from 'styled-components'

import Example from './helpers/spinner'

const move = keyframes`
0% {
letter-spacing: 1.5px
}
45% {
letter-spacing: 2.4px
}
55% {
    letter-spacing: 2.4px
}
100% {
    letter-spacing: 1.5px
}
`

const Form = styled.div`
width: 20%;
margin: auto;
text-align: center;
form {
    input {
        margin: 2% auto;
        margin-bottom: 4%;
    }
    button {
        margin: auto 2%;
    }
}
`

const Logo = styled.p`
padding-top: 10%;
display: block;
margin: auto;
width: 20%;
animation: ${move} .7s linear infinite;
text-align: center;
font-size: 3rem;
margin-bottom: 1%;
&:hover {
    cursor: default;
}
`

// const Flex = styled.div`
// font-size: 1.4rem;
// display: flex;
// justify-content: space-evenly;
// width: 20%;
// margin: auto;
// `

const initialFormValues = {name: "", password: ""}

const LandingPage = ({dark, light, darkMode}) => {
    const [formValues, setFormValues] = useState(initialFormValues)
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false)

    const {push} = useHistory();


    
    // const handleLogInSubmit = e => {
    //     e.preventDefault();
    //     const postedObj = {name: formValues.name, password: formValues.password}
    //     axios.post(`${process.env.REACT_APP_DB}/auth/login`, postedObj)
    //     .then(res=>{
    //         window.localStorage.setItem("userId", res.data.id)
    //         window.localStorage.setItem("handWidth", 70)
    //         window.localStorage.setItem('appearance', 'dark')
    //         window.localStorage.setItem("tilesColored", "colored")
    //         window.localStorage.setItem("handAlign", "center")
    //         window.localStorage.setItem("handTiles", 3)
    //         window.localStorage.setItem("groupSize", 3)
    //         push('/lobby')
    //     })
    //     .catch(err=>{
    //         setErrorMessage("Invalid username or password")
    //         console.log(err);
    //     })
    // }

    const handleChange = e => {
        e.preventDefault()
        setFormValues({...formValues, [e.target.name]: e.target.value})
    }

    const handleRegisterSubmit = async e => {
        e.preventDefault();
        setLoading(true)
        const postedObj = {name: formValues.name, password: formValues.password}
        axios.post(`${process.env.REACT_APP_DB}/auth/register`, postedObj)
        .then(res=>{
            window.localStorage.setItem("userId", res.data.id)
            window.localStorage.setItem("handWidth", 70)
            window.localStorage.setItem('appearance', 'dark')
            window.localStorage.setItem("tilesColored", "colored")
            window.localStorage.setItem("handAlign", "center")
            window.localStorage.setItem("handTiles", 3)
            window.localStorage.setItem("groupSize", 3)
            setLoading(false)
            push('/lobby')
        })
        .catch(err=>{
            setLoading(false)
            setErrorMessage("Name is taken, enter a different name")
        })
    }

    return (
        <div style={{minHeight: "100vh"}}>
            <Logo>RUMMIKUB</Logo>
            {/* <Flex>
                <p onClick={handleToggleChange}>LOGIN</p>
                <p onClick={handleToggleChange}>REGISTER</p>
            </Flex> */}
                    {!loading ? <Form>
                    <form>
                        <label htmlFor="name">ENTER YOUR NAME<br/>
                            <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            value={formValues.name}/>
                        </label><br/>
                        {/* <label htmlFor="password">Password<br/>
                            <input
                            type="password"
                            name="password"
                            value={formValues.password}
                            onChange={handleChange}/>
                        </label><br/> */}
                        <button style={{padding: "2%", borderRadius: "5px",backgroundColor: `${darkMode ? light : dark}`, color: `${darkMode ? dark : light}`, border: `solid ${darkMode ? dark : light} 2px`}} onClick={handleRegisterSubmit}>SUBMIT</button>
                    </form>
                    {errorMessage && <p>{errorMessage}</p>}
                
            </Form> :
            <div style={{display: "flex", justifyContent: "center"}}><Example color={darkMode ? light : dark} type={"spin"}/></div>}
            
        </div>
    )
}

export default LandingPage
