import React, {useState} from 'react';
import {Route} from 'react-router-dom';

import Lobby from './Lobby';
import GameWaitingRoom from './GameWaitingRoom'
import Game from './Game';
import GameOver from './GameOver'
import LandingPage from './LandingPage'







import { QueryClient, QueryClientProvider } from 'react-query';


const queryClient = new QueryClient()




function App() {

  let initialAppearance = window.localStorage.getItem("appearance")


  const [darkMode, setDarkMode] = useState(initialAppearance === "dark" || !initialAppearance)

  const dark = "#363636";
  const light = "#ECECEC"



  return (

    <div style={darkMode === true ? {color: "#ECECEC", backgroundColor: "#363636", margin: '-.59%'} : {margin: "-.59", color: "#363636", backgroundColor: "#ECECEC"}}>
        <QueryClientProvider client={queryClient}>
          <Route exact path="/">
            <LandingPage dark={dark} light={light} darkMode={darkMode} />
          </Route>
          <Route exact path="/lobby">
              <Lobby dark={dark} light={light}  darkMode={darkMode}/>
          </Route>
          <Route exact path="/game-waiting-room/:gameId">
            <GameWaitingRoom dark={dark} light={light} darkMode={darkMode} />
          </Route>
          <Route exact path="/game/:gameId">
            <Game dark={dark} light={light} darkMode={darkMode} setDarkMode={setDarkMode}/>
          </Route>
          <Route exact path='/game-over/:gameId'>
            <GameOver dark={dark} light={light} />
          </Route>
        </QueryClientProvider>

    </div>
  );
}

export default App;
