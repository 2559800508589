import React, {useState} from 'react'
import {useParams, useHistory} from 'react-router-dom';
import {useQuery} from 'react-query'
import axios from 'axios';
// import styled from 'styled-components'

import Hand from './Hand';
import Board from './Board'
import Container from './RightSide/Container'

import {usePageVisibility} from './helpers/helpers2'



// const UpperArea = styled.div`
// flex-direction: row;
// justify-content: space-between;
// `

// const Buttons = styled.div`
// display: flex;
// justify-content: center;
// flex-direction: row;
// button {
//     margin: auto 1%;
// }
// `




const Game = ({darkMode, setDarkMode}) => {
    const [curPlay, setCurPlay] = useState([])
    const [takenFromHand, setTakenFromHand] = useState([])
    const [handPiecesOnBoard, setHandPiecesOnBoard] = useState([])

    const [boardCopy, setBoardCopy] = useState([])
    const [originalBoardState, setOriginalBoardState] = useState([])
    const [amountFetched, setAmountFetched] = useState(0);
    const [newGroupIds, setNewGroupIds] = useState([])
    const [submittingFirstGroup, setSubmittingFirstGroup] = useState(false)
    
    
    // const [newGroupStarted, setNewGroupStarted] = useState(false)
    const [moves, setMoves] = useState(0);


    // const [backup, setBackup] = useState([])

    const [loading, setLoading] = useState(false)

    const [wildTime, setWildTime] = useState(false)
    const [oldWildIndex, setOldWildIndex] = useState(null)
    const [newWildIndex, setNewWildIndex] = useState(null)
    const [withWildTime, setWithWildTime] = useState(false)
    const [ownTilesWithWild, setOwnTilesWithWild] = useState(null)
    const [ownTilesWithNewGroups, setOwnTilesWithNewGroups] = useState({})

    const [message, setMessage] = useState("")


    const initialHandWidth = window.localStorage.getItem("handWidth")
    const initialHandTiles = window.localStorage.getItem("handTiles")
    const initialGroupSize = window.localStorage.getItem("groupSize")
    const initialTilesColored = window.localStorage.getItem("tilesColored")
    const initialHandAlign = window.localStorage.getItem("handAlign")
    
    

    const [width, setWidth] = useState({handWidth: Number(initialHandWidth), handTiles: Number(initialHandTiles), groupSize: Number(initialGroupSize)})

    const [handAlign, setHandAlign] = useState(initialHandAlign)


    const [tilesColored, setTilesColored] = useState(initialTilesColored === "colored")

    const isVisible = usePageVisibility()


    const dark = "#363636";
    const light = "#ECECEC"
    
    






    const {push} = useHistory();
    const {gameId} = useParams();
    const playerId = window.localStorage.getItem("playerId");
    // const userId = window.localStorage.getItem("userId");
    const theGameId = window.localStorage.getItem("gameId")
    if (Number(gameId) !== Number(theGameId)) {
        push('/')
    }
    


    const handleChange = e => {
        e.preventDefault()
        e.stopPropagation()
        setWidth({...width, [e.target.name]: Number(e.target.value)})
        window.localStorage.setItem(e.target.name, e.target.value)
    }



    // (single player) put settings with options for automatic turn change and not automatic, difficulty, 

    // react query queries and functions

    // get players pieces
    const getPlayerPieces = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/pieces`)
        return res.json()
    }

    const {data: playerPieces, status: playerPiecesStatus} = useQuery(`game${gameId}player${playerId}pieces`, getPlayerPieces, {refetchInterval: 1000})

    // get game info /turn info etc
    const getGameInfo = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/play/${gameId}/info`)
        return res.json()
    }

    const {data: gameInfo, status: gameInfoStatus} = useQuery(`game${gameId}gameInfo`, getGameInfo, {refetchInterval: 1000})

        // boardPieces/plays
        const getBoardInfo = async () => {
            const res = await fetch(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
            return res.json()
        }
    
        const {data: boardInfo, status: boardStatus} = useQuery(`game${gameId}boardInfo`, getBoardInfo, {refetchInterval: 1000})

        // playerInfo

        const getPlayerInfo = async () => {
            const res = await fetch(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/game-player-info`)
            return res.json()
        }
    
        const {data: playerInfo, status: playerStatus} = useQuery(`game${gameId}player${playerId}game-player-info`, getPlayerInfo, {refetchInterval: 1000})



                // declaring/initializing variables 

                let turnId;
                let turnName;
                let players;
                let myTurn;
        
           
                // let nextInOrder;
                // let justMoved;
                if (gameInfoStatus === "success" && boardStatus === "success") {
                    if (!gameInfo.gameInfo) {
                        const userId = window.localStorage.getItem("userId")
                        axios.delete(`${process.env.REACT_APP_DB}/games/delete/${userId}`)
                        .then(res=>{
                            window.localStorage.removeItem("userId");
                            window.localStorage.removeItem("handWidth")
                            window.localStorage.removeItem("handTiles")
                            window.localStorage.removeItem("groupSize")
                            window.localStorage.removeItem("appearance")
                            window.localStorage.removeItem("tilesColored")
                            window.localStorage.removeItem("playerId")
                            window.localStorage.removeItem("gameId")
                            window.localStorage.removeItem("handAlign")
                            window.location.reload(true);
                            push('/')
                        })
                        .catch(err=>{
                            console.log(err.message)
                        })

                    }
                    turnId = Number(gameInfo.gameInfo.turn_id)
                    turnName = gameInfo.gameInfo.turn_name
                    players = [...gameInfo.players]
                    // order = [...gameInfo.turnOrder]
                    // startedBy = gameInfo.started_by
                    // for (let i = 0; i < order.length; i++) {
                    //     if (order[i] === Number(playerId)) {
                    //         if (i === order.length - 1) {
                    //             nextInOrder = order[0]
                    //             break;
                    //         } else {
                    //             nextInOrder = order[i + 1]
                    //         }
                    //     }
                    // }
                   
                    if (gameInfo.gameInfo.completed === 1) {
                            push(`/game-over/${gameId}`)
                        

              
                        
                    }
                    myTurn = Number(turnId) === Number(playerId)
                    // if (isVisible && myTurn) {
                    //     seen = true
                    // } else {
                    //     seen = false
                    // }
                }

                let playerName;
                if (playerStatus === "success") {
                    playerName = playerInfo.name
                }


                // if (myTurn && seen !== null && seen !== undefined && seen === false) {
                //     axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
                //     .then(res=>{
                //         setBoardCopy([...res.data])
                //         setOriginalBoardState([...res.data])
                //         setAmountFetched(1)
                //     })
                //     .catch(err=>{
                //         console.log(err.message)
                //     })
                // }

                // if (turnId !== undefined) {
                    
                //     if (amountFetched < 1 && myTurn && isVisible) {
                        
                //         axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
                //         .then(res=>{
                //             setBoardCopy([...res.data])
                //             setOriginalBoardState([...res.data])
                //             setAmountFetched(1)
                //         })
                //         .catch(err=>{
                //             console.log(err.message)
                //         })
                //         }
                // }
                
                // if (boardStatus === "success" && boardInfo && myTurn) {
                //     if (amountFetched < 1) {
                //     setBoardCopy([...boardInfo])
                //     setOriginalBoardState([...boardInfo])
                //     setAmountFetched(1)
                //     }
                // }

                // may have to create object to keep track of original pieces play_id/other info i will need when i go to post new play


                
                // const theyMatch = () => {
                //     let newBoardCopy = boardCopy.filter((arr, i)=>{
                //         return arr.every(piece=>typeof piece.id === "number" && typeof piece.game_id === "number" && typeof piece.is_wild === "number" && typeof piece.play_id === "number" && typeof piece.play_order === "number" && typeof piece.temp_value === "number" && typeof piece.value === "number")
                //     })
                //     return newBoardCopy.length === boardInfo.length
                // }

                // if (playerStatus === "success") {
                // }

                let movedOnce;
                let hasDrawn;
                let stillInTurn;
                // let needToRefresh;
                if (playerStatus === "success" && gameInfoStatus === "success" && Number(gameInfo.gameInfo.turn_id) === Number(playerId)) {
                    if (playerInfo.moved_once === 0) {
                        movedOnce = false
                    } else {
                        movedOnce = true
                    }
                    
                    if (playerInfo.drawn_once === 0) {
                        hasDrawn = false
                    } else {
                        hasDrawn = true;
                    }

                    if (playerInfo.still_in_turn === 0) {
                        stillInTurn = false
                    } else {
                        stillInTurn = true
                    }
                    
                    // if (playerInfo.need_to_refresh === 1) {
                    //     needToRefresh = true;
                    // } else {
                    //     needToRefresh = false;
                    // }
            }






            
                const mouseMoveHelper = e => {
                    // add conditional to check if board copy is different than actualboardstatus
                    // let theyMatch;
                    // if (boardStatus === "success") {
                    //     if (boardCopy.length !== boardInfo.length) {
                    //         theyMatch = false;
                    //     } else {
                    //         if (!boardCopy.every((array, i)=>{
                    //             return boardInfo[i].length === array.length
                    //         })) {
                    //             theyMatch = false
                    //         } else {
                    //             boardCopy.forEach((arr, index)=>{
                    //                 for (let i = 0; i < arr.length; i++) {
                    //                     if (boardInfo[index][i].value !== boardInfo[index][i].value && boardInfo[index[i]].color !== boardInfo[index[i]].color) {
                    //                         theyMatch = false
                    //                         return
                    //                     } else {
                    //                         return
                    //                     }
                    //                 }
                    //             })
                    //             if (theyMatch === undefined || theyMatch === null) {
                    //                 theyMatch = true
                    //             }
                    //         }
                    //     }
                    // }

                   

                    // if (myTurn && playerStatus === "success" && gameInfoStatus === "success") {

                    //     axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
                    //     .then(res=>{
                    //         setBoardCopy([...res.data])
                    //     })
                    //     .catch(err=>{
                    //         console.log(err.message)
                    //         window.location.reload(true)
                    //     })
                    



            //         if (playerInfo.need_to_refresh === 1) {
            //         axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/board-refresh`)
            //         .then(res=>{
            //                 window.location.reload(true)
            //         })
            //         .catch(err=>{
            //             console.log(err)
            //         })
                    
            // }

            if (amountFetched < 1 && myTurn && isVisible) {
                axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
                .then(res=>{
                    setBoardCopy([...res.data])
                    setOriginalBoardState([...res.data])
                    setAmountFetched(1)
                })
                .catch(err=>{
                })
                }


        }

        if (amountFetched > 0 && !myTurn) {
            setAmountFetched(0)
        }




        // if (myTurn && !isVisible) {
        //     const wow = setInterval(()=>{
        //         window.location.reload(true)
        //     }, 5000)
        //     if (isVisible) {
        //         clearInterval(wow)
        //     }
        // }




        // useEffect(()=>{
        //         axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
        //         .then(res=>{
        //             setBoardCopy([...res.data])
        //         })
        //         .catch(err=>{
        //             console.log(err.message)
        //             window.location.reload(true)
        //         })
        // }, [])
        

                // if (myTurn && !theyMatch() && boardStatus === "success") {
                //     setBoardCopy([...boardInfo])
                // }

                // if (myTurn && wow < 1) {
                //     setWow(wow + 1)
                //     window.location.reload(true);
                // }

                // useEffect(()=>{
                //     if (myTurn && amountFetched < 1) {
                //         axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
                //         .then(res=>{
                //             console.log('wow')
                //             setAmountFetched(amountFetched + 1)
                //             setBoardCopy(res.data)
                //             setOriginalBoardState(res.data)
                //         })
                //         .catch(err=>{
                //             console.log(err.message)
                //         })
                //     }
                // })


                // let boardCopy;
                // let originalBoardState;
                // if (boardStatus === "success") {
                //     boardCopy = [...boardInfo]
                //     originalBoardState = [...boardInfo]
                // }
        
        
    
        // const getWildVal = (groupId, wildId) => {
        //     const group = boardCopy[groupId];
        //     let wildIndex;
        //     for (let i = 0; i < group.length; i++) {
        //         let curPiece = group[i]
        //         if (curPiece.id === wildId) {
        //             wildIndex = i
        //             break
        //         }
        //     }
        //     let wildColor;
        //     let wildValue;

        //     const colorsArray = group.map(play=>play.color)
        //     const valuesArray = group.map(play=>{
        //         if (play.value === "Wild" || play.value === 0) {
        //             return "Wild"
        //         } else {
        //             return Number(play.value)
        //         }
        //     })
            
        //     let firstColor;
        //     for (let i = 0; i < colorsArray.length; i++) {
        //         if (valuesArray[i] === "Wild" || valuesArray[i] === 0) {
        //             continue
        //         } else {
        //             firstColor = colorsArray[i];
        //             break;
        //         }
        //     }
    
          
    
            
        //     const colorsMatch = colorsArray.every((color, i)=>{
        //         if (valuesArray[i] === "Wild" || valuesArray[i] === 0) {
        //             return true
        //         } else {
        //         return color === firstColor
        //     }
        // });

        //     for (let i = 0; i < group.length; i++) {
        //         if (i === wildIndex) {
        //             let piece = group[i]
        //             if (i === 0) {
        //                 let nextPiece = group[i + 1]
        //                 if (colorsMatch) {

        //                 }
        //             } else if (i === group.length - 1) {
        //                 let prevPiece = group[i - 1]
        //             } else {
        //                 let prevPiece = group[i - 1]
        //                 let nextPiece = group[i + 1]
        //             }
        //         }
        //     }
        // }



    // Dragging functions

   const dragFunctions = {
        handleDragStart(e) {
            e.dataTransfer.dropEffect = "move";
            e.dataTransfer.setData("text/plain", e.target.outerHTML)
        },
        handleDragEnter(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        handleDragLeave(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        handleDragOver(e) {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.dropEffect = "move";
        },
        // handleDropInPlay(e) {
        //     e.preventDefault();
        //     e.stopPropagation();

        //     const data = e.dataTransfer.getData("text/plain");
        //     const dataArraySplit = data.split(/id|style|>/)
        //     const id = dataArraySplit[1].split('"')[1]
        //     const color = dataArraySplit[2].split(/\s|;/)[1]
        //     const value = dataArraySplit[4].split("<")[0]

        //     let fromHand;
        //     let fromBoard;
        //     fromHand = id.split(/[a-z]/).length === 5
        //     fromBoard = dataArraySplit[1].split(" ").length === 5;

        //     let newId;
        //     if (id.split("h").length > 1) {
        //         newId = id;
        //     } else {
        //         newId = id + "hand"
        //     }

        //     const newPiece = {id: newId, color, value, player_id: Number(playerId), game_id: Number(gameId)}

        //     setCurPlay([...curPlay, newPiece])
        //     if (!takenFromHand.includes(id)) {
        //         let newId;
        //         if (id.split("h").length > 1) {
        //             newId = id
        //         } else {
        //             newId = id + "hand"
        //         }
        //         const newPlayerPieces = [...takenFromHand, newId]
        //         const arr = [];
        //         for (let i = 0; i < newPlayerPieces.length; i++) {
        //             if (!arr.includes(newPlayerPieces[i])) {
        //                 arr.push(newPlayerPieces[i])
        //             }
        //         }
        //         setTakenFromHand([...arr])
        //     }
        //     if (fromBoard) {
        //         const boardGroupIndex = dataArraySplit[1].split(" ")[3].split('"')[0]
        //         const newBoardInfo = [...boardCopy].map((arr, i)=>{
        //             if (i === Number(boardGroupIndex)) {
        //                 let newArr = [...arr].filter(piece=>{
        //                     return piece.id !== id
        //                 })
        //                 return newArr
        //             } else {
        //                 return arr
        //             }
        //         })
        //         setMoves(moves + 1)
        //         setBoardCopy([...newBoardInfo])
        //         return
        //     }
        //     },
        handleDropInBoardButOnTile(e) {
            e.preventDefault();
            e.stopPropagation();
            // const data = e.dataTransfer.getData("text/plain");
            
        },
        handleDropInBoard(pieceData, newGroupId, newIndex) {
            if (!myTurn) {
                return
            }
            if (movedOnce) {
                boardDropHelper(pieceData, newGroupId, newIndex)
            } else {
                boardDropHelper2(pieceData, newGroupId, newIndex)
            }
        }
   }
        

        

        const boardDropHelper2 = (pieceData, newGroupId, newIndex) => {
            if (!newGroupIds.includes(Number(newGroupId))) {
                return
            }
            if (newGroupIds.includes(Number(newGroupId))) {
                const splitData = pieceData.split('"')
                let fromBoard
                const fromHand = splitData[3].split("h").length === 2
                fromBoard = !fromHand
                let oldGroupId;
                if (fromBoard) {
                    oldGroupId = splitData[5].split(" ")[2]
                    if (Number(oldGroupId) !== Number(newGroupId)) {
                        setMessage("You must add a group of tiles with a total value of at least 30 before making any other moves on the board!")
                        return
                    }

                }
      
                
                const value = splitData[8].split(/>|</)[3]
                const color = splitData[7].split(/\s|;/)[7]
                const id = splitData[3].split("h")[0]
                if (takenFromHand.includes(id + "hand")) {
                    return
                }
      
                // let play_id;
                // let game_id
                // let temp_value
                // let is_wild
                // if (boardCopy[newGroupId].length > 0) {
                //     play_id = Number(boardCopy[newGroupId][0].play_id)
                //     game_id = Number(gameId)
                //     temp_value = null
                //     is_wild = false
                // } else {
                //     play_id = "none"
                //     game_id = "none" 
                //     temp_value = "none" 
                //     is_wild = "none" 
                // }
    
     
                const newPiece = {value, color, id: Number(id)}
                let newId;
                if (fromHand) {
                    newId = id + "hand"
                    let newTakenFromHand = [...takenFromHand]
                    newTakenFromHand.push(newId)
                    if (!takenFromHand.includes(newId)) {
                        setTakenFromHand([...takenFromHand, newId])
                    }
                    setHandPiecesOnBoard([...handPiecesOnBoard, newId])
                }
                
     


                let newBoardInfo = boardCopy.map((arr, i)=>{
                    if (i === Number(newGroupId)) {
                        let newArr = arr.map((obj, i)=>({piece: obj, index: i}))
                        newArr.push({piece: {...newPiece}, index: newIndex - 1})
                        return newArr
                    } else if (i === Number(oldGroupId)) {
                        let newGroup = [...arr].filter(piece=>{
                            return Number(piece.id) !== Number(id)
                        })
                        return newGroup
                    } else {
                        return arr
                    }
                })
                let arrayToEdit = [...newBoardInfo[Number(newGroupId)]]
            
            
                for (let i = 0; i < arrayToEdit.length; i++) {
                    let curPiece = arrayToEdit[i]
                    if (Number(curPiece.index) >= Number(newIndex)) {
                        if (Number(curPiece.id) !== Number(newPiece.id) && Number(curPiece.value) !== Number(newPiece.value) && curPiece.color !== newPiece.color) {
                            curPiece.index += 1
                        }
                    }
                }
    
                let newlyUpdated = arrayToEdit.sort((a,b)=>a.index < b.index ? -1 : a.index > b.index ? 1 : 0).map(obj=>{
                    return {...obj.piece}
                })
    
                newBoardInfo[Number(newGroupId)] = [...newlyUpdated]

                // const newBoardInfo = boardCopy.map((arr, i)=>{
                //     if (i === Number(newGroupId)) {
                //         let newArr = [...arr]
                //         newArr.push(newPiece)
                //         return newArr;
                //     }
                //     else if (i === Number(oldGroupId)) {
                //         let newArr = arr.filter(piece=>{
                //             return Number(piece.id) !== Number(newPiece.id)
                //         })
                //         return newArr
                //     }
                //     else {
                //         return arr
                //     }
                // })
                setMoves(moves + 1)
                setBoardCopy(newBoardInfo)
                setMessage("")
                return
            } else {
                setMessage("You need to add a group of tiles with a total value of at least 30 points before you can add other tiles to the board")
                return
            }
        }





        const boardDropHelper = (pieceData, newGroupId, newIndex) => {




            const splitData = pieceData.split('"')
            let fromBoard
            const fromHand = splitData[3].split("h").length === 2
            fromBoard = !fromHand

            


            let oldGroupId;
            if (fromBoard) {
                oldGroupId = splitData[5].split(" ")[2]
            }


            
            let value;
            if (fromHand || Number(newGroupId) === Number(oldGroupId)) {
                value = splitData[8].split(/>|</)[3]
            } else {
                if (splitData[splitData.length - 1].split(/<|>/).length === 4) {
                    value = 0
                } else {
                    value = splitData[splitData.length - 1].split(/<|>/)[3]
                }
            }
            let color;
            if (fromHand) {
                color = splitData[7].split(/\s|;/)[7]
            } else if (Number(newGroupId) === Number(oldGroupId)) {
                color = splitData[7].split(/\s|;/)[10]
            } else {
                color = splitData[7].split(/:|\s|;/)[14]
            }
            const id = splitData[3].split("h")[0]
            let play_id;
            let game_id
            let temp_value
            let is_wild
            if (boardCopy[newGroupId].length > 0) {
                play_id = Number(boardCopy[newGroupId][0].play_id)
                game_id = Number(gameId)
                temp_value = null
                is_wild = false
            } else {
                play_id = "none"
                game_id = "none" 
                temp_value = "none" 
                is_wild = "none" 
            }

 
            const newPiece = {value, color, id: Number(id), play_id, game_id, temp_value, is_wild}

            if (fromHand) {
              
                let newId = id + "hand"
                let newTakenFromHand = [...takenFromHand]
                newTakenFromHand.push(newId)
                if (!takenFromHand.includes(newId)) {
                    setTakenFromHand(newTakenFromHand)
                }
                setHandPiecesOnBoard([...handPiecesOnBoard, newId])
            }


            if (newGroupIds.includes(newGroupId) && fromHand) {

                if (ownTilesWithNewGroups.hasOwnProperty(newGroupId)) {
                    let num = ownTilesWithNewGroups[newGroupId]
                    num = num + 1
                    setOwnTilesWithNewGroups({...ownTilesWithNewGroups, [newGroupId]: num})
                } else {
                    setOwnTilesWithNewGroups({...ownTilesWithNewGroups, [newGroupId]: 1})
                }
            }

            if (wildTime === true) {
                if (fromBoard) {
                    return
                }
                let newBoardInfo = boardCopy.map((arr, i)=>{
                    if (i === Number(newGroupId)) {
                        let newArr = arr.map((obj, i)=>({piece: obj, index: i}))
                        newArr.push({piece: {...newPiece}, index: newIndex - 1})
                        return newArr
                    } else if (i === Number(oldGroupId)) {
                        let newGroup = [...arr].filter(piece=>{
                            return Number(piece.id) !== Number(id)
                        })
                        return newGroup
                    } else {
                        return arr
                    }
                })
                let arrayToEdit = [...newBoardInfo[Number(newGroupId)]]
            
            
                for (let i = 0; i < arrayToEdit.length; i++) {
                    let curPiece = arrayToEdit[i]
                    if (Number(curPiece.index) >= Number(newIndex)) {
                        if (Number(curPiece.id) !== Number(newPiece.id) && Number(curPiece.value) !== Number(newPiece.value) && curPiece.color !== newPiece.color) {
                            curPiece.index += 1
                        }
                    }
                }
    
                let newlyUpdated = arrayToEdit.sort((a,b)=>a.index < b.index ? -1 : a.index > b.index ? 1 : 0).map(obj=>{
                    return {...obj.piece}
                })
    
                newBoardInfo[Number(newGroupId)] = [...newlyUpdated]

                if (!isValidPlay(newBoardInfo[Number(newGroupId)])) {
                    return
                }

                setBoardCopy([...newBoardInfo])

                let theNum
                for (let prop in ownTilesWithNewGroups) {
                    if (Number(prop) === Number(newWildIndex)) {
                        theNum = Number(ownTilesWithNewGroups[prop])
                    }

                }
                if (theNum === undefined) {
                    theNum = 0
                }
           
                if (theNum >= 2) {
                    setWildTime(false)
                    setMessage("")
                    return
                } else {

                setOwnTilesWithWild(2 - theNum)
                setWildTime(false)
                setWithWildTime(true)
                setMessage(`ADD ${2 - theNum} OF YOUR OWN TILES TO NEW SET WITH JOKER`)
                return
            } }




            if (withWildTime === true) {
                if (fromBoard) {
                    return
                }
                // if (Number(newGroupId) !== boardCopy.length - 1) {
                //     console.log('wow')
                //     return
                // }
                // if (!fromHand) {
                //     return
                // } else {
                    
                    let newBoardInfo = boardCopy.map((arr, i)=>{
                        if (i === Number(newGroupId)) {
                            let newArr = arr.map((obj, i)=>({piece: obj, index: i}))
                            newArr.push({piece: {...newPiece}, index: newIndex - 1})
                            return newArr
                        } else if (i === Number(oldGroupId)) {
                            let newGroup = [...arr].filter(piece=>{
                                return Number(piece.id) !== Number(id)
                            })
                            return newGroup
                        } else {
                            return arr
                        }
                    })
                    let arrayToEdit = [...newBoardInfo[Number(newGroupId)]]
                
                
                    for (let i = 0; i < arrayToEdit.length; i++) {
                        let curPiece = arrayToEdit[i]
                        if (Number(curPiece.index) >= Number(newIndex)) {
                            if (Number(curPiece.id) !== Number(newPiece.id) && Number(curPiece.value) !== Number(newPiece.value) && curPiece.color !== newPiece.color) {
                                curPiece.index += 1
                            }
                        }
                    }
        
                    let newlyUpdated = arrayToEdit.sort((a,b)=>a.index < b.index ? -1 : a.index > b.index ? 1 : 0).map(obj=>{
                        return {...obj.piece}
                    })
        
                    newBoardInfo[Number(newGroupId)] = [...newlyUpdated]
    
                    
                    setBoardCopy([...newBoardInfo])
                    if (ownTilesWithWild === 1) {
                        setWithWildTime(false)
                        setOwnTilesWithWild(0)
                        setOldWildIndex(null)
                        setNewWildIndex(null)
                        setMessage('')
                        return
                    } else {
                        setOwnTilesWithWild(1)
                        setMessage(`ADD 1 OF YOUR OWN TILES TO NEW SET WITH JOKER`)
                    }
                // }
            }












            let isWildFromBoard;
            

            if (fromBoard && newPiece.value === 0) {
                isWildFromBoard = true
            } else {
                isWildFromBoard = false
            }


            if (isWildFromBoard === true && !withWildTime) {
                if (Number(newGroupId) === boardCopy.length - 1) {
                    setMessage("SELECT ONE OF YOUR TILES TO REPLACE JOKER")
                    setWildTime(true)
                    setOldWildIndex(Number(oldGroupId))
                    setNewWildIndex(Number(newGroupId))
                } else {
                    return
                }
                
            }

            



            let newBoardInfo;
            

            if (boardCopy[newGroupId].length === 0) {
                newBoardInfo = boardCopy.map((arr, i)=>{
                    if (i === Number(newGroupId)) {
                        let newArr = [...arr]
                        newArr.push(newPiece)
                        return newArr;
                    }
                    if (i === Number(oldGroupId)) {
                        let newArr = arr.filter(piece=>{
                            return Number(piece.id) !== Number(newPiece.id)
                        })
                        return newArr
                    }
                    else {
                        return arr
                    }
                })
                setBoardCopy(newBoardInfo)
                if (fromHand) {
                    setMoves(moves + 1)
                }

                return
            }


            if (Number(newGroupId) !== Number(oldGroupId)) {
                newBoardInfo = boardCopy.map((arr, i)=>{
                    if (i === Number(newGroupId)) {
                        let newArr = arr.map((obj, i)=>({piece: obj, index: i}))
                        newArr.push({piece: {...newPiece}, index: newIndex - 1})
                        return newArr
                    } else if (i === Number(oldGroupId)) {
                        let newGroup = [...arr].filter(piece=>{
                            return Number(piece.id) !== Number(id)
                        })
                        return newGroup
                    } else {
                        return arr
                    }
                })
                let arrayToEdit = [...newBoardInfo[Number(newGroupId)]]
            
            
                for (let i = 0; i < arrayToEdit.length; i++) {
                    let curPiece = arrayToEdit[i]
                    if (Number(curPiece.index) >= Number(newIndex)) {
                        if (Number(curPiece.id) !== Number(newPiece.id) && Number(curPiece.value) !== Number(newPiece.value) && curPiece.color !== newPiece.color) {
                            curPiece.index += 1
                        }
                    }
                }
    
                const newlyUpdated = arrayToEdit.sort((a,b)=>a.index < b.index ? -1 : a.index > b.index ? 1 : 0).map(obj=>{
                    return {...obj.piece}
                })
    
                newBoardInfo[Number(newGroupId)] = [...newlyUpdated]
    
    
                setBoardCopy([...newBoardInfo])
                if (fromHand) {
                    setMoves(moves + 1)
                }

                // const newTakenFromHand = [...takenFromHand].filter(str=> !str === id + "hand")
                const newCurPlay = [...curPlay].filter(play=> play.id !== splitData[3])
                setCurPlay(newCurPlay)
        } else {
            newBoardInfo = boardCopy.map((arr, i)=>{
                if (i === Number(newGroupId)) {
                    let newArr = arr.map((obj, i)=>{
                        return {piece: obj, index: i}
                    })
                    return newArr
                } else if (i === Number(oldGroupId)) {
                    let newGroup = [...arr].filter(piece=>{
                        return Number(piece.id) !== Number(id)
                    })
                    return newGroup
                } else {
                    return arr
                }
            })
            let arrayToEdit = [...newBoardInfo[Number(newGroupId)]]
            
            let selectedPieceOriginalIndex;

            for (let i = 0; i < arrayToEdit.length; i++) {
                const curPiece = arrayToEdit[i].piece
                if (newPiece.value === "Wild" || newPiece.value === 0 || !newPiece.value) {
                    if (curPiece.value === newPiece.value && curPiece.color === newPiece.color && curPiece.id === newPiece.id) {
                        selectedPieceOriginalIndex = i
                        break;
                    }
                } else {
                    if (Number(curPiece.value) === Number(newPiece.value) && curPiece.color === newPiece.color && Number(curPiece.id) === Number(newPiece.id)) {
                        selectedPieceOriginalIndex = i
                        break
                    }
                }

            }

            let newArr = []
            newArr.push({...arrayToEdit[selectedPieceOriginalIndex], index: newIndex})

            for (let i = 0; i < arrayToEdit.length; i++) {
                let curPiece = arrayToEdit[i]
                if (i === selectedPieceOriginalIndex) {
                    continue
                }
                if (i > newIndex - 1) {
                    curPiece.index += 1
                }
                newArr.push({...curPiece})
                
            }

            const newlyUpdated = newArr.sort((a,b)=>a.index < b.index ? -1 : a.index > b.index ? 1 : 0).map(obj=>{
                return {...obj.piece}
            })

            newBoardInfo[Number(newGroupId)] = [...newlyUpdated]

        }
            setBoardCopy([...newBoardInfo])
            if (fromHand) {
                setMoves(moves + 1)
            }

        
        

        
        }
            // helper functions

    const isValidPlay = (arr) => {
        if (arr.length < 3) {
            return false;
        }
        const colorsArray = arr.map(play=>play.color)
        const valuesArray = arr.map(play=>{
            if (play.value === "Wild" || play.value === 0 || !play.value) {
                return "Wild"
            } else {
                return Number(play.value)
            }
        })
        
        let firstColor;
        for (let i = 0; i < colorsArray.length; i++) {
            if (valuesArray[i] === "Wild" || valuesArray[i] === 0) {
                continue
            } else {
                firstColor = colorsArray[i];
                break;
            }
        }

      

        
        const colorsMatch = colorsArray.every((color, i)=>{
            if (valuesArray[i] === "Wild" || valuesArray[i] === 0) {
                return true
            } else {
            return color === firstColor
        }
    });

        if (colorsMatch) {
            for (let i = 0; i < valuesArray.length - 1; i++) {
                let thisNum = valuesArray[i]
                let nextNum = valuesArray[i + 1]
                let prevNum;
                if (i > 0) {
                    prevNum = valuesArray[i - 1]
                } else {
                    if (valuesArray[i] === "Wild" || valuesArray[i] === 0) {
                        continue
                    }
                }
                if (thisNum === "Wild" || thisNum === 0) {
                    if (nextNum === "Wild" || nextNum === 0) {
                        continue
                    } else {
                        if (prevNum === "Wild" || prevNum === nextNum - 2 || prevNum === 0) {
                            continue
                        } else {
                            return false
                        }
                    }
                }
                if (nextNum === thisNum + 1 || nextNum === "Wild" || nextNum === 0) {
                    continue
                } else {
                    return false
                }
            }
            return true
        } else {

            const colors = []
            for (let i = 0; i < colorsArray.length; i++) {
                if (valuesArray[i] === "Wild") {
                    continue
                }
                if (colors.includes(colorsArray[i])) {
                    return false
                } else {
                    colors.push(colorsArray[i])
                }
            }

            let firstValue;
            for (let i = 0; i < valuesArray.length; i++) {
                if (valuesArray[i] === "Wild" || valuesArray[i] === 0) {
                    continue;
                } else {
                    firstValue = valuesArray[i];
                    break;
                }
            }

            for (let i = 0; i < colorsArray.length; i++) {
                if (valuesArray[i] !== firstValue) {
                    if (valuesArray[i] !== "Wild" || valuesArray[i] === 0) {
                        return false
                    } else {
                        continue
                    }
                } else {
                    continue
                }
            }
            return true
        }
    }

    const getGroupValue = arr => {
        // const values = arr.map(piece=>{
        //     if (piece.value === "Wild") {
        //         return 0
        //     } else {
        //         return Number(piece.value)
        //     }
        // })

        // const wilds = values.filter(num=>num === 0)
        // const notWilds = values.filter(num=>num !== 0)
        
        let values = []
        // let firstValIndex;
        // for (let i = 0; i < arr.length - 1; i++) {
        //     if (arr[i].value === "Wild") {
        //         continue
        //     } else {
        //         firstValIndex = i;
        //     }
        // }

        let firstColor;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].value === "Wild") {
                continue
            } else {
                firstColor = arr[i].color
                break
            }
        }

        let colorsMatch = arr.every(piece=>{
            if (piece.value === "Wild" || piece.value === 0 || !piece.value) {
                return true
            } else {
                return piece.color === firstColor
            }
        })
        if (!colorsMatch) {
            let sum = 0;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].value === "Wild" || arr[i].value === 0 || !arr[i].value) {
                    if (i === arr.length - 1) {
                        sum += values[values.length - 1]
                        break
                    } else {
                        let foundVal;
                        for (let j = i + 1; j < arr.length; j++) {
                            if (arr[j].value === "Wild" || arr[j].value === 0 || !arr[j].value) {
                                continue
                            } else {
                                sum += Number(arr[j].value)
                                values.push(Number(arr[j].value))
                                foundVal = true;
                                break;
                            }
                        }
                        if (!foundVal) {
                            sum += Number(values[values.length - 1])
                            values.push(Number(values[values.length - 1]))
                        }
                    }

                } else {
                    values.push(Number(arr[i].value))
                    sum += Number(arr[i].value)
                }
            }
            return sum
        } else {
            let sum = 0;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].value === "Wild" || arr[i].value === 0 || arr[i].value === null || !arr[i].value) {
                    if (i === arr.length - 1) {
                        sum += values[values.length - 1] + 1
                        break
                    } else {
                        let foundVal;
                        for (let j = i + 1; j < arr.length; j++) {
                            if (arr[j].value === "Wild" || arr[j].value === 0 || arr[j].value === null || !arr[j].value) {
                                continue
                            } else {
                                sum += Number(arr[j].value)
                                values.push(Number(arr[j].value))
                                foundVal = true;
                                break;
                            }
                        }
                        if (!foundVal) {
                            sum += Number(values[values.length - 1])
                            values.push(Number(values[values.length - 1]))
                        }
                    }

                } else {
                    values.push(Number(arr[i].value))
                    sum += Number(arr[i].value)
                }
            }
            return sum
        }
    }


    // HAVE TO ADD CONDITIONAL TO CHECK IF IT'S THEIR FIRST TURN, THEIR FIRST TURN MUST EQUAL 30 OR MORE

    const draw = e => {
        e.preventDefault();
        setLoading(true)
        
        // let getRefreshed;
        // if (playerStatus === "success") {
        //     if (playerInfo.get_refreshed === 1) {
        //         getRefreshed = false
        //     } else {
        //         getRefreshed = true
        //     }
        // }
        if (turnId === Number(playerId)) {
            axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/draw`)
            .then(res=>{
                setTimeout(()=>{
                    setAmountFetched(0)
                    setLoading(false)
                }, 500)
            })
            .catch(err=>{
                setLoading(false)
            })
        }
    }



    // const addPlayToBoard = e => {
    //     e.preventDefault();
    //     const newPieces = [...curPlay].map(obj=>{
    //         let newId;
    //         if (obj.id.split("h").length > 1) {
    //             newId = Number(obj.id.split("h")[0])
    //         } else {
    //             newId = Number(obj.id)
    //         }
    //         return {...obj, id: newId}
    //     })

    //     // player pieces to delete
    //     const newTakenFromHand = takenFromHand.map(str=>{
    //         return Number(str.split("h")[0])
    //     })
    //     const postedObj = {pieces: newPieces, playerPiecesToDelete: newTakenFromHand}
    //     if (turnId === Number(playerId) && isValidPlay()) {
    //         axios.post(`${process.env.REACT_APP_DB}/play/${gameId}/board`, postedObj)
    //         .then(res=>{
    //             setCurPlay([])
    //             setTakenFromHand([])
    //             axios.get(`${process.env.REACT_APP_DB}/play/${gameId}/board`)
    //             .then(res=>{
    //                 setBoardCopy([...res.data])
    //                 setOriginalBoardState([...res.data])
    //             })
    //         })
    //         .catch(err=>{
    //             console.log(err)
    //         })
    //     }
    // }

    const clearHand = e => {
        e.preventDefault();
        setTakenFromHand([])
        setCurPlay([])
    }

    const resetBoard = e => {
        e.preventDefault();
        setTakenFromHand([])
        setCurPlay([])
        setBoardCopy([...originalBoardState])
        setNewGroupIds([])
        setOwnTilesWithNewGroups({})
        setMoves(0)
        setWildTime(false)
        setWithWildTime(false)
        setOwnTilesWithWild(0)
        setOldWildIndex(null) 
        setNewWildIndex(null)
        setMessage("")
    }
    const addNewGroupToBoard = e => {
        e.preventDefault();
        let newBoardCopy = [...boardCopy, []]
        setBoardCopy(newBoardCopy)
        setNewGroupIds([...newGroupIds, boardCopy.length])
        setMessage("")
    }

    const endTurn = async () => {

        if (!boardCopy.every(arr=>{return isValidPlay(arr) || arr.length === 0})) {
            setMessage("There are one or more groups of tiles on the board that are invalid. Fix them or reset the board and try again")
            setLoading(false)
            return
        }

        if (turnId === Number(playerId)) {
            // for (let i = 0; i < boardCopy.length; i++) {
            //     if (!isValidPlay(boardCopy[i])) {
            //         alert("mistakes!")
            //         return 
            //     }
            // }
            const playerPiecesToDelete = takenFromHand.map(str=>{
                let arr = str.split("h")
                return Number(arr[0])
            })
            
            // let getRefreshed;
            // if (playerStatus === "success") {
            //     if (playerInfo.get_refreshed === 1) {
            //         getRefreshed = false
            //     } else {
            //         getRefreshed = true
            //     }
            // }

            const unfiltered = boardCopy.map((arr, i)=>{
                // let arrToReturn = []
                let newArr = arr.map((piece, idx)=>{
                    let newValue;
                    let isWild;
                    if (piece.value === "Wild") {
                        newValue = 0;
                        isWild = true
                    } else {
                        newValue = Number(piece.value)
                        isWild = false
                    }
                    const newPiece = {...piece, game_id: gameId, value: newValue, is_wild: isWild, temp_value: 0}
                    delete newPiece.play_id
                    delete newPiece.id
                    return newPiece
                })
                return newArr
            })

            const newBoardCopy = unfiltered.filter(arr=>arr.length > 0)
            
            try {

                setLoading(true)
                

                await axios.post(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/end-turn-move`, {playerPiecesToDelete, newBoardCopy})

                setMoves(0)

                
                
                await axios.post(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/change-turns`, {})



                

                setTimeout(() => {
                    setLoading(false)
                    setTakenFromHand([])
                    setNewGroupIds([])
                    setOwnTilesWithNewGroups({})
                    setAmountFetched(0)
                    setWildTime(false)
                    setWithWildTime(false)
                    setOwnTilesWithWild(0)
                    setOldWildIndex(null) 
                    setNewWildIndex(null)
                    setMessage("")
                }, 700);
                
            } catch (err) {
                setLoading(false)
                setMoves(0)
                setTakenFromHand([])
                setNewGroupIds([])
                setOwnTilesWithNewGroups({})
                setAmountFetched(0)
                setWildTime(false)
                setWithWildTime(false)
                setOwnTilesWithWild(0)
                setOldWildIndex(null) 
                setNewWildIndex(null)
                setMessage("")
            }
        }
    }


    const addFirstGroupHelperTwo = async () => {
        // if (getGroupValue(boardCopy[boardCopy.length - 1]) < 30) {
        //     setMessage("Your first group of tiles added to the board must have a total value of at least 30")
        //     setLoading(false)
        //     return
        // }
        if (!isValidPlay(boardCopy[boardCopy.length - 1])) {
            setMessage("The group of tiles you're attempting to add to the board is invalid. Fix it or cancel and draw a tile to end your turn")
            setLoading(false)
            return
        }
        
    
        const playerPiecesToDelete = takenFromHand.map(str=>{
            const num = Number(str.split("h")[0])
            return num
        })

        const newBoard = boardCopy.map(arr=>{
            const newArr = arr.map(obj=>{
                let newValue;
                if (obj.value === "Wild") {
                    newValue = Number(0)
                } else {
                    newValue = Number(obj.value)
                }
                return {...obj, value: newValue}
            })
            return newArr
        })


        const postedObj = {playerPiecesToDelete, newBoard}
        try {
        await axios.post(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/pre-first-move`)

        setNewGroupIds([])
        setOwnTilesWithNewGroups({})
        setMoves(0)
        
        await axios.post(`${process.env.REACT_APP_DB}/play/${gameId}/${playerId}/first-move`, postedObj)

        setTimeout(()=>{
            setLoading(false)
            setTakenFromHand([])
            setAmountFetched(0)
            setWildTime(false)
            setWithWildTime(false)
            setOwnTilesWithWild(0)
            setOldWildIndex(null) 
            setNewWildIndex(null)
        }, 1200)

        
    
        } catch (err) {
            setLoading(false)
            setTakenFromHand([])
            setNewGroupIds([])
            setOwnTilesWithNewGroups({})
            setMoves(0)
            setAmountFetched(0)
            setWildTime(false)
            setWithWildTime(false)
            setOwnTilesWithWild(0)
            setOldWildIndex(null) 
            setNewWildIndex(null)
        }
    
    
    }



    return (
        <div style={{minHeight: "100vh", paddingTop: "1%"}} onMouseOver={mouseMoveHelper}>
            {boardStatus === "success" && <Board gameInfo={gameInfo} gameInfoStatus={gameInfoStatus} boardStatus={boardStatus} turnId={turnId} playerId={playerId} turnName={turnName} players={players} playerName={playerName} loading={loading} setLoading={setLoading} endTurn={endTurn} setMessage={setMessage} addFirstGroupHelperTwo={addFirstGroupHelperTwo} draw={draw} endturn={endTurn}  moves={moves} ownTilesWithWild={ownTilesWithWild} message={message} stillInTurn={stillInTurn} dark={dark} light={light} darkMode={darkMode} tilesColored={tilesColored} width={width} wildTime={wildTime} withWildTime={withWildTime} newWildIndex={newWildIndex} oldWildIndex={oldWildIndex} submittingFirstGroup={submittingFirstGroup} setSubmittingFirstGroup={setSubmittingFirstGroup} hasDrawn={hasDrawn} resetBoard={resetBoard} newGroupIds={newGroupIds} setNewGroupIds={setNewGroupIds} movedOnce={movedOnce} getGroupValue={getGroupValue} addNewGroupToBoard={addNewGroupToBoard} dropHandler={dragFunctions.handleDrop} isValidPlay={isValidPlay} setBoardCopy={setBoardCopy} takenFromHand={takenFromHand} dragFunctions={dragFunctions} boardCopy={boardCopy} originalBoardState={originalBoardState} myTurn={myTurn} boardInfo={boardInfo}/>}
            {curPlay.length > 0 && <p>{isValidPlay(curPlay) ? "valid" : "not valid"}</p>}
            {/* <PlayArea isValidPlay={isValidPlay} handPiecesOnBoard={handPiecesOnBoard} takenFromHand={takenFromHand} dragFunctions={dragFunctions} curPlay={curPlay} setCurPlay={setCurPlay}/> */}
            {curPlay.length > 0 && <button onClick={clearHand}>CLEAR HAND</button>}
            {playerPiecesStatus === "success" && <Hand handAlign={handAlign} dark={dark} light={light} boardCopy={boardCopy} darkMode={darkMode} tilesColored={tilesColored} width={width} handPiecesOnBoard={handPiecesOnBoard} curPlay={curPlay} takenFromHand={takenFromHand} dragFunctions={dragFunctions} pieces={playerPieces}/>}
                {/* <PlayerTurnList message={message} setMessage={setMessage} dark={dark} light={light} darkMode={darkMode} setBoardCopy={setBoardCopy} handleChange={handleChange} width={width}  ownTilesWithWild={ownTilesWithWild} oldWildIndex={oldWildIndex} withWildTime={withWildTime} setWithWildTime={setWithWildTime} wildTime={wildTime} setWildTime={setWildTime} playerName={playerName} loading={loading} setLoading={setLoading} needToRefresh={needToRefresh} getGroupValue={getGroupValue} wow={wow} setWow={setWow} boardCopy={boardCopy} submittingFirstGroup={submittingFirstGroup} setSubmittingFirstGroup={setSubmittingFirstGroup} addFirstGroupHelperTwo={addFirstGroupHelperTwo} endTurn={endTurn} moves={moves} stillInTurn={stillInTurn} hasDrawn={hasDrawn} myTurn={myTurn} addNewGroupToBoard={addNewGroupToBoard} newGroupIds={newGroupIds} endturn={endTurn} playerId={playerId} movedOnce={movedOnce} draw={draw} resetBoard={resetBoard} turnId={turnId} turnName={turnName} players={players}/> */}
                <Container myTurn={myTurn} handAlign={handAlign} setHandAlign={setHandAlign} dark={dark} light={light} darkMode={darkMode} setDarkMode={setDarkMode} tilesColored={tilesColored} setTilesColored={setTilesColored} handleChange={handleChange} width={width} setWidth={setWidth} dragFunctions={dragFunctions} />
        </div>
    )
}

export default Game
