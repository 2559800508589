import React from 'react';
import {useParams, useHistory} from 'react-router-dom'
import {useQuery} from 'react-query'
import GameWaitingRoomPlayerInfo from './GameWaitingRoomPlayerInfo'

import MessageBoard from './MessageBoard'


const GameWaitingRoom = ({dark, light, darkMode}) => {


    

    const {push} = useHistory();


    const {gameId} = useParams();
    const playerId = window.localStorage.getItem("playerId");
    const theGameId = window.localStorage.getItem("gameId")

    if (!playerId) {
        push('/')
    }
    if (Number(gameId) !== Number(theGameId)) {
        push('/')
    }

    const getPlayers = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/games/${gameId}/players`)
        return res.json()
    }

    const {data: playersData, status: playersDataStatus} = useQuery(`game${gameId}PlayersInfo`, getPlayers, {refetchInterval: 500})

    
    const getGameInfo = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/games/${gameId}`)
        return res.json()
    }

    const {data: gameInfo, status: gameInfoStatus} = useQuery(`game${gameId}info`, getGameInfo, {refetchInterval: 5000})


    let startedBy;
    let playersReady;
    if (gameInfoStatus === "success") {
        startedBy = gameInfo.started_by
    }
    if (playersDataStatus === "success") {
        playersReady = playersData.every(player=>player.ready === 1)
        if (gameInfoStatus === "success" && gameInfo.started === 1 && gameInfo.startedBy !== Number(playerId) && playersReady) {
            push(`/game/${gameId}`)
        }
    }


    

    return (
        <div style={{minHeight: "100vh"}}>
            <div style={{textAlign: "center", width: "20%", margin: "auto", paddingTop: "1%", paddingBottom: "2%"}}>
            {playersDataStatus === "success" && playersData.map(player=>{
                return (
                    <GameWaitingRoomPlayerInfo playersReady={playersReady} startedBy={startedBy} darkMode={darkMode} dark={dark} light={light} playersDataStatus={playersDataStatus} key={player.id} playerInfo={player}/>
                )
            })}
            {playersReady && startedBy !== Number(playerId) && <p>All players are ready. Only the user who created the game can start the game.</p>}
            </div>
            <div >
            <MessageBoard inGame={false}/>
            </div>
        </div>
    )
}

export default GameWaitingRoom
