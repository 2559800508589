import React from 'react'
import styled from 'styled-components';
import redJoker from './img/redjoker.png'
import blackJoker from './img/blackjoker.png'

const StyledPiece = styled.div`
border: solid black 3px;
text-align: center;
margin: 1% 1%;
border-radius: 4px;
`


const Piece = ({tilesColored, pieces, width, piece, dragFunctions }) => {



    // const valString = piece.value + ""
    
    let theWidth;
    let theFont;
    let theColor;
    let paddingBottom
    

  
    if (width.handWidth === 40) {
        if (width.handTiles === 1) {
            theWidth = "6.3%"
            theFont = '1rem'
            paddingBottom = "44%"
        } else if (width.handTiles === 3) {
            theWidth = "8.1%"
            theFont = '1.3rem'
            paddingBottom = "46.9%"
        } else if (width.handTiles === 5) {
            theWidth = "9.4%"
            theFont = "1.5rem"
            paddingBottom = "46.7%"
        }
    } else if (width.handWidth === 45) {
        if (width.handTiles === 1) {
            theWidth = "5.6%"
            theFont = '1rem'
            paddingBottom = "45%"
        } else if (width.handTiles === 3) {
            theWidth = "7.19%"
            theFont = '1.3rem'
            paddingBottom = "47.4%"
        } else if (width.handTiles === 5) {
            theWidth = "8.25%"
            theFont = "1.5rem"
            paddingBottom = "48.8%"
        }
    } else if (width.handWidth === 50) {
        if (width.handTiles === 1) {
            theWidth = "6.4%"
            theFont = '1.3rem'
            paddingBottom = "48.2%"
        } else if (width.handTiles === 3) {
            theWidth = "8.7%"
            theFont = '1.8rem'
            paddingBottom = "49.9%"
        } else if (width.handTiles === 5) {
            theWidth = "10.6%"
            theFont = "2.2rem"
            paddingBottom = "51%"
        }
    } else if (width.handWidth === 55) {
        if (width.handTiles === 1) {
            theWidth = "5.7%"
            theFont = '1.3rem'
            paddingBottom = "51.2%"
        } else if (width.handTiles === 3) {
            theWidth = "7.95%"
            theFont = '1.8rem'
            paddingBottom = "49.35%"
        } else if (width.handTiles === 5) {
            theWidth = "9.6%"
            theFont = "2.2rem"
            paddingBottom = "50%"
        }
    } else if (width.handWidth === 60) {
        if (width.handTiles === 1) {
            theWidth = "5.2%"
            theFont = '1.3rem'
            paddingBottom = "49.52%"
        } else if (width.handTiles === 3) {
            theWidth = "7.26%"
            theFont = '1.8rem'
            paddingBottom = "50.1%"
        } else if (width.handTiles === 5) {
            theWidth = "8.9%"
            theFont = "2.2rem"
            paddingBottom = "48.2%"
        }
    } else if (width.handWidth === 65) {
        if (width.handTiles === 1) {
            theWidth = "4.77%"
            theFont = '1.3rem'
            paddingBottom = "51.1%"
        } else if (width.handTiles === 3) {
            theWidth = "6.75%"
            theFont = '1.8rem'
            paddingBottom = "48.1%"
        } else if (width.handTiles === 5) {
            theWidth = "8%"
            theFont = "2.2rem"
            paddingBottom = "51.8%"
        }
    } else if (width.handWidth === 70) {
        if (width.handTiles === 1) {
            theWidth = "4.5%"
            theFont = '1.3rem'
            paddingBottom = "50.3%"
        } else if (width.handTiles === 3) {
            theWidth = "6.3%"
            theFont = '1.8rem'
            paddingBottom = "47.1%"
        } else if (width.handTiles === 5) {
            theWidth = "7.6%"
            theFont = "2.2rem"
            paddingBottom = "48.1%"
        }
    } else if (width.handWidth === 75) {
        if (width.handTiles === 1) {
            theWidth = "4.15%"
            theFont = '1.3rem'
            paddingBottom = "48.9%"
        } else if (width.handTiles === 3) {
            theWidth = "6.05%"
            theFont = '1.8rem'
            paddingBottom = "44%"
        } else if (width.handTiles === 5) {
            theWidth = "6.95%"
            theFont = "2.2rem"
            paddingBottom = "50.55%"
        }
    } else if (width.handWidth === 80) {
        if (width.handTiles === 1) {
            theWidth = "3.73%"
            theFont = '1.3rem'
            paddingBottom = "56%"
        } else if (width.handTiles === 3) {
            theWidth = "5.3%"
            theFont = '1.8rem'
            paddingBottom = "52.9%"
        } else if (width.handTiles === 5) {
            theWidth = "6.55%"
            theFont = "2.2rem"
            paddingBottom = "50%"
        }
    } else if (width.handWidth === 85) {
        if (width.handTiles === 1) {
            theWidth = "3.55%"
            theFont = '1.3rem'
            paddingBottom = "52.8%"
        } else if (width.handTiles === 3) {
            theWidth = "5%"
            theFont = '1.8rem'
            paddingBottom = "50.75%"
        } else if (width.handTiles === 5) {
            theWidth = "6.25%"
            theFont = "2.2rem"
            paddingBottom = "47.15%"
        }
    } else if (width.handWidth === 90) {
        if (width.handTiles === 1) {
            theWidth = "3.37%"
            theFont = '1.3rem'
            paddingBottom = "52.32%"
        } else if (width.handTiles === 3) {
            theWidth = "4.78%"
            theFont = '1.8rem'
            paddingBottom = "49.3%"
        } else if (width.handTiles === 5) {
            theWidth = "6%"
            theFont = "2.2rem"
            paddingBottom = "45.61%"
        }
    } else if (width.handWidth === 95) {
        if (width.handTiles === 1) {
            theWidth = "3.2%"
            theFont = '1.3rem'
            paddingBottom = "51.3%"
        } else if (width.handTiles === 3) {
            theWidth = "4.53%"
            theFont = '1.8rem'
            paddingBottom = "49.2%"
        } else if (width.handTiles === 5) {
            theWidth = "5.47%"
            theFont = "2.2rem"
            paddingBottom = "50.4%"
        }
    }

    if (tilesColored === false) {
        theColor = 'white'
    } else if (piece.color === "orange") {
        theColor = "#fff4eb"
    } else if (piece.color === "red") {
        theColor = "#fff0f0"
    } else if (piece.color === "blue") {
        theColor = "#ebebff"
    } else if (piece.color === "black") {
        theColor = "#f2f2f2"
    }



    return (
        <StyledPiece style={{width: theWidth, fontSize: theFont, color: `${piece.color}`, backgroundColor: theColor, display: `${piece.value === 0 ? "flex" : "defaut"}`, flexDirection: "column", justifyContent: "flex-start", alignContent: "stretch"}} onDragLeave={e=>{e.preventDefault(); e.stopPropagation();}} onDragEnter={e=>{e.preventDefault(); e.stopPropagation();}} onDragOver={e=>{e.preventDefault(); e.stopPropagation();}} draggable="true" id={("" + piece.id).split("h").length === 1 ? piece.id + "hand" : piece.id} onDragStart={dragFunctions.handleDragStart}>
            {piece.value !== 0 ? <p>{piece.value}</p> : <img alt="joker" draggable="false" style={{maxWidth: "100%", borderRadius: "50%", paddingBottom, transform: "scale(.7)"}} src={piece.color === "red" ? redJoker : blackJoker}/>}
        </StyledPiece>
    )
}

export default Piece
