import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import Emojis from './Emojis'


import Message from './Message'
import TypeMessage from './TypeMessage'

const TheMessageArea = styled.div`
width: 40%;
border-width: 3px;
border-style: solid;
overflow-y: scroll;
display: flex;
flex-direction: column-reverse;
justify-content: flex-start;
border-radius: 3px;

`

const Div = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
`

const Flex = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
width: 60%;
`


const MessageBoard = ({darkMode, inGame, handleDragEnter, handleDragLeave, handleDragOver , myTurn}) => {
    const [message, setMessage] = useState("");
    const [emojisToggled, setEmojisToggled] = useState(false);

    const toggleEmojis = e => {
        e.preventDefault();
        setEmojisToggled(!emojisToggled)
    }

    const light = "#363636";
    const dark = "#ECECEC"



    const {gameId} = useParams();
    const playerId = window.localStorage.getItem("playerId");

    const getMessages = async () => {
        const res = await fetch(`${process.env.REACT_APP_DB}/games/${gameId}/messages`)
        return res.json()
    }

    const {data: messagesData, status: messagesStatus} = useQuery(`game${gameId}MessagesForWaitingRoom`, getMessages, {refetchInterval: 1000})


    const handleChange = e => {
        e.preventDefault();
        setMessage(e.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        if (message.length <= 140) {
        const postedObj = {message: message, player_id: Number(playerId)}
        axios.post(`${process.env.REACT_APP_DB}/games/${gameId}/messages`, postedObj)
        .then(res=>{
            setEmojisToggled(false)
            setMessage("")
        })
        .catch(err=>{
            setEmojisToggled(false)
            setMessage("")
        })
    }
}


    return (
        <>  
        {myTurn && <p style={{color: `${darkMode ? light : dark}`, textAlign: "center"}}>(IT'S YOUR TURN)</p>}
        <Flex>
                <TheMessageArea style={{maxHeight: "30vh", borderColor: `${darkMode ? light : dark}`}}>
                {messagesStatus === "success" && messagesData.length > 0 && messagesData.map(message=>{
                    return (
                        <Message key={message.id} messageInfo={message}/>
                    )
                })}
                {messagesStatus === "success" && messagesData.length === 0 && <p style={{textAlign: "center"}}>BE THE FIRST TO TRASH TALK!</p>}
                </TheMessageArea>
                {emojisToggled && <Emojis emojisToggled={emojisToggled} setMessage={setMessage} handleSubmit={handleSubmit} handleChange={handleChange} message={message}/>}
                
                <Div>
                    <TypeMessage darkMode={darkMode} light={light} dark={dark} emojisToggled={emojisToggled} toggleEmojis={toggleEmojis} setMessage={setMessage} message={message} handleChange={handleChange} handleSubmit={handleSubmit}/>
                </Div>
                </Flex>
        </>
    )
}

export default MessageBoard
