import React from 'react'
import styled from 'styled-components'

const Span = styled.span`
font-weight: bold;
text-decoration: underline;
`

const Message = ({messageInfo}) => {
    // let hourStr = messageInfo.hour + ""
    // let hour;
    // if ((/^0/).test(hourStr)) {
    //     hour = hourStr.replace(hourStr.charAt(0), '')
    // } else {
    //     hour = hourStr;
    // }
    return (
            <p style={{marginTop: ".04%", marginLeft: "1%"}}> <Span>{messageInfo.posted_by}:</Span>    {messageInfo.message}</p>
    )
}

export default Message
